import { Pipe, PipeTransform } from '@angular/core';
import { Config } from '@app/_configs';
import * as moment from 'moment';

@Pipe({ name: 'dateFormat' })
export class MomentPipe implements PipeTransform {
    transform(value: any, dateFormat: string, inputFormat = Config.backendDateFormat): any {
      moment.locale('pl');
      if (value) {
        if (value?.date) {
          return moment(value?.date, inputFormat).format(dateFormat);
        } else {
          return moment(value, inputFormat).format(dateFormat);
        }
      }
    }
}
