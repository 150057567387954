import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '@app/_configs';
import { User } from '@app/_models';
import { Device } from '@capacitor/device';
import { Geolocation } from '@capacitor/geolocation';
import { StatusBar, Style } from '@capacitor/status-bar';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { StorageService } from '.';

@Injectable({
	providedIn: 'root'
})
export class CommonService {

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private translate: TranslateService) {}

  async isIos() {
    const device = await Device.getInfo();
		return device.platform === 'ios';
	}

  async isWeb() {
    const device = await Device.getInfo();
		return device.platform === 'web';
	}

  async isAndroid() {
    const device = await Device.getInfo();
		return device.platform === 'android';
	}

  isUndefined(prop: any) {
    return typeof prop === 'undefined';
  }

  onScroll(event) {
    const el = document.getElementById('bulb');
    if (el) {
      const radius = 80 - (event.detail.scrollTop / 2.28) + 'px';
      el.style.borderBottomLeftRadius = radius;
      el.style.borderBottomRightRadius = radius;
    }
  }

  openMainMenu() {
    document.body.classList.add('main-menu-open');
  }

  async setStatusBar(style: Style): Promise<void> {
    if (await this.isWeb() === false) {
      await StatusBar.setStyle({ style });
    }
  }

  async setStatusBarBackground(color: string): Promise<void> {
    if (await this.isAndroid() === true) {
      await StatusBar.setBackgroundColor({ color });
    }
  }

  async checkPermissions() {
    if (await this.isWeb()) {
      return navigator.permissions.query({name: 'geolocation'}).then(async result => result.state);
    } else {
      const permissions = await Geolocation.requestPermissions();
      return permissions.location;
    }
  }

  formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0 || typeof bytes === 'undefined') {
      return '0 B';
    };

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  formatKNumber(num: number, digits: number = 0) {
    const units = ['K', 'M'];
    let decimal;

    for(let i = units.length - 1; i >= 0; i--) {
        decimal = Math.pow(1000, i + 1);

        if(num <= -decimal || num >= decimal) {
            return +(num / decimal).toFixed(digits) + units[i];
        }
    }

    return num;
  }

  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], {type: mimeString});
    return blob;
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  convertBase64ToBlob = (b64Data: any, contentType: string = '', sliceSize: number = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  setMonthNames(translate: TranslateService) {
    const arr = [];
    for (let i = 1; i <= 12; i++) {
      arr.push(translate.instant(`MONTHS.${i}`));
    }
    return arr;
  }

  getCountriesByLang() {
    const lang = this.translate.currentLang;
    return this.http.get(`assets/data/iso.countries.${lang}.json`);
  }

  getCountryName(country) {
    const countries = JSON.parse(this.storage.get('countries'));
    const c = countries.find(item => item.code === (typeof country === 'object' ? country.code : country));
    if (c) {
      return c.name;
    }
    return null;
  }

  country(country) {
    if (country) {
      if (typeof country === 'string') {
        return country;
      }
      return country.name;
    }
    return null;
  }

  prefix(prefix) {
    if (prefix) {
      if (typeof prefix === 'string') {
        return prefix;
      }
      return prefix.dial_code;
    }
    return null;
  }

  generateFileName() {
    const timestamp = moment().format(Config.fileNameTimestampFormat);
    return `photo_${timestamp}`;
  }

  fileSizeHasBeenExceeded(size: number) {
    if (size > Config.uploadFileSizeInBytes) {
      return true;
    }
    return false;
  }

  isImageFileType(mimeType: string) {
    return mimeType.startsWith('image');
  }

  fileTypeNotAllowed(mimeType: string) {
    if (Config.allowedFileTypes.indexOf(mimeType) !== -1) {
      return false;
    }
    return true;
  }

  purchaseSellFileTypeNotAllowed(mimeType: string) {
    if (Config.allowedPurchaseSellFileTypes.indexOf(mimeType) !== -1) {
      return false;
    }
    return true;
  }

  canEdit(userId: number) {
    const me: User = JSON.parse(this.storage.get('user'));
    return me.id === userId;
  }

  isBasicSubscription() {
    const user: User = this.storage.user;
    return user?.current_subscription?.plan_slug === 'basic';
  }
}



