import { Component } from '@angular/core';
import { CommonService } from '@app/_services/common.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.layout.scss']
})
export class AuthComponent {
  registrationLink = environment?.features?.singleStepRegistration ? '/register' : '/register/select-account-type';

  constructor(public common: CommonService) {}
}
