export const environment = {
  production: false,
  baseUrl: 'https://platform.earnwiseapp.com',
  apiUrl: 'https://platform.earnwiseapp.com/api',
  mercureHubUrl: 'https://platform.earnwiseapp.com/mercure',
  firebaseConfig: {
    apiKey: 'AIzaSyD0nt7ndUDYUx4fLfOLnLXW5pNeUJ_NBNk',
    authDomain: 'earnwiseapp-4b5cb.firebaseapp.com',
    projectId: 'earnwiseapp-4b5cb',
    storageBucket: 'earnwiseapp-4b5cb.appspot.com',
    messagingSenderId: '221643789536',
    appId: '1:221643789536:web:ba0d5c1168eee6d77cec85',
    measurementId: 'G-QYNBLVEWV5'
  },
  googleConfig: {
    clientId: "221643789536-9fjrlce02lite794u8vv77s0hi8iiq7o.apps.googleusercontent.com",
    scopes: ["profile", "email"]
  },
  features: {
    singleStepRegistration: true
  }
};
