import { Injectable } from '@angular/core';
import { Tag } from '@app/_interfaces/tag.interface';
import { Post, TableResponse } from '@app/_models';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '.';

@Injectable({providedIn: 'root'})
export class WallService {

  selectedTags: string[] = [];
  public postUpdated = new Subject<{post: Post, type: string}>();
  public postUpdated$ = this.postUpdated.asObservable();

  constructor(private api: ApiService) {
  }

  postUpdate(post: Post, type: string) {
    this.postUpdated.next({
      post,
      type
    });
  }

  initWall(posts: Post[]) {
    const newPosts = this.createPosts(posts);
    const allTags = this.getAllTags(newPosts);

    return new Promise<{posts: Post[], tags: Tag[]}>((resolve, reject) => {
      if (newPosts) {
        resolve({posts: newPosts, tags: allTags});
      }
      reject({error: ''});
    });
  }

  createPosts(posts: Post[]) {
    const arr = [];
    for (const p of posts) {
      arr.push(new Post(p));
    }
    return arr;
  }

  getAllTags(posts: Post[]) {
    const arr = [];
    for (const p of posts) {
      if (!arr.includes(p.type)) {
        arr.push(p.type);
      }
    }
    return arr.map(item => ({
      type: item,
      selected: false
    }));
  }

  getTags(tag: Tag) {
    tag.selected = !tag.selected;
    if (tag.selected) {
      if (!this.selectedTags.includes(tag.type)) {
        this.selectedTags.push(tag.type);
      }
    } else {
      this.selectedTags = this.selectedTags.filter(t => t !== tag.type);
    }

    return this.selectedTags.join(',');
  }

  getSelectedTags() {
    return this.selectedTags;
  }

  clearSelectedTags() {
    this.selectedTags = [];
  }

  getPosts(params: any = {}): Observable<TableResponse<Post[]>> {
		return this.api.get(`announcements`, params).pipe(map(response => response as TableResponse<Post[]>));
	}

  showPost(id: number): Observable<Post> {
		return this.api.get(`announcements/${id}`).pipe(map(response => response as Post));
	}

  getMyBookmarks(params: any = {}): Observable<TableResponse<Post[]>> {
		return this.api.get(`announcements/bookmarks`, params).pipe(map(response => response as TableResponse<Post[]>));
	}

  getPredefinedTags(): Observable<any[]> {
		return this.api.get(`parameters/tags`).pipe(map(response => response as any[]));
	}

  addOrRemoveFromBookmark(id: number, action: string): Observable<any> {
		return this.api.put(`announcements/${id}/bookmarks-action/${action}`, {});
	}

  likeOrUnlike(id: number, action: string): Observable<any> {
    return this.api.put(`likes/announcements/${id}/${action}`, {});
  }

  likeOrUnlikeRealization(id: number, action: string): Observable<any> {
    return this.api.put(`likes/user-realizations/${id}/${action}`, {});
  }

  dislikeOrUndislike(id: number, action: string): Observable<any> {
    return this.api.put(`dislikes/announcements/${id}/${action}`, {});
  }

  dislikeOrUndislikeRealization(id: number, action: string): Observable<any> {
    return this.api.put(`dislikes/user-realizations/${id}/${action}`, {});
  }

  updatePost(id: number, data: any): Observable<any> {
		return this.api.put(`announcements/${id}`, data);
	}

  uploadPostFile(id: number, data: any): Observable<any> {
		return this.api.post(`announcements/${id}/images`, data).pipe(map(response => response as any));
	}

  createPost(data: any): Observable<any> {
		return this.api.post(`announcements`, data);
	}

  removePostImage(id: number, token: string): Observable<any> {
		return this.api.delete(`announcements/${id}/images/${token}`);
	}

  removeAnnouncement(id: number): Observable<any> {
		return this.api.delete(`announcements/${id}`);
	}
}
