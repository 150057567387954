import { Injectable } from '@angular/core';
import { BaseComponent } from '@app/_components/base.component';
import { EventTask } from '@app/_interfaces/event-task.interface';
import { Tab } from '@app/_interfaces/tab.interface';
import { FileResponse } from '@app/_models/file-response';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationsService } from '.';
import { ApiService } from './api.service';
import { ErrorService } from './error.service';


@Injectable()
export class EventService extends BaseComponent {

  public fileUploaded = new Subject<boolean>();
  public fileUploaded$ = this.fileUploaded.asObservable();

  public refreshTasks = new Subject<{task: EventTask, type: string}>();
  public refreshTasks$ = this.refreshTasks.asObservable();

  constructor(
    private api: ApiService,
    private errorService: ErrorService,
    private notifications: NotificationsService,
    private translate: TranslateService) {
      super();
    }


    refreshTaskList(task, type = 'add') {
      this.refreshTasks.next({task, type});
    }

  uploadTaskFiles(files: File[], taskId: number, id: number) {
    try {
      for (const f of files) {
        this.uploadMultipleFiles(f, taskId, id);
      }
    } catch(err) {
      console.log('TRYCATCH EROR: ', err);
    }
  }

  uploadMultipleFiles(file: any, taskId: number, id: number) {
    const fd = new FormData();
    fd.append('file', file, file.name);
    this.startUpload(fd, taskId, id);
  }

  startUpload(fd: FormData, taskId: number, id: number) {
    const sub = this.uploadTaskFile(id, taskId, fd).subscribe(() => {
      this.fileUploaded.next(true);
    }, error => {
      if (this.errorService.showMessage(error)) {
        this.notifications.onError(this.translate.instant(`ERRORS.cant_upload_file`));
      }
    });
    this.subscriptions.add(sub);
  }


  // api calls

  getEvents(id: number, day: string, params: any = {}): Observable<EventTask[]> {
    return this.api.get(`investments/${id}/events?date=${day}`, params).pipe(map(response => response as EventTask[]));
  }

  getAllEvents(day: string, params: any = {}): Observable<EventTask[]> {
    return this.api.get(`investments/events?date=${day}`, params).pipe(map(response => response as EventTask[]));
  }

  getDatesWithTasks(id: number, range: {from: string, to: string}): Observable<string[]> {
    return this.api.get(`investments/${id}/events-dates?date_range=${range.from},${range.to}`).pipe(map(response => response as string[]));
  }

  getAllDatesWithTasks(range: {from: string, to: string}): Observable<string[]> {
    return this.api.get(`investments/events-dates?date_range=${range.from},${range.to}`).pipe(map(response => response as string[]));
  }

  getTabList(id: number): Observable<Tab[]> {
    return this.api.get(`investments/${id}/event-groups`).pipe(map(response => response as Tab[]));
  }

  getFilesList(id: number, taskId: number): Observable<FileResponse> {
    return this.api.get(`investments/${id}/events/${taskId}/files`).pipe(map(response => response as FileResponse));
  }

  addTask(id: number, data: EventTask): Observable<{id: number}> {
    return this.api.post(`investments/${id}/events`, data).pipe(map(response => response as {id: number}));
  }

  addTab(id: number, data: {name: string}): Observable<{token: string}> {
    return this.api.post(`investments/${id}/event-groups`, data).pipe(map(response => response as {token: string}));
  }

  updateTask(id: number, taskId: number, data: EventTask) {
    return this.api.put(`investments/${id}/events/${taskId}`, data);
  }

  updateTab(id: number, token: string, data: any) {
    return this.api.put(`investments/${id}/event-groups/${token}`, data);
  }

  removeTask(id: number, taskId: number): Observable<any> {
    return this.api.delete(`investments/${id}/events/${taskId}`);
  }

  removeTab(id: number, token: string, removeWithTasks: boolean): Observable<any> {
    return this.api.delete(`investments/${id}/event-groups/${token}/${removeWithTasks ? 'remove-all' : 'remove'}`);
  }

  changeStatus(id: number, taskId: number, data: {completed: boolean}): Observable<any> {
    return this.api.put(`investments/${id}/events/${taskId}/completed`, data);
  }

  uploadTaskFile(id: number, taskId: number, data: any): Observable<any> {
		return this.api.post(`investments/${id}/events/${taskId}/files`, data).pipe(map(response => response as any));
	}

}
