import { Injectable, Pipe, PipeTransform } from '@angular/core';
import Autolinker from 'autolinker';

@Injectable()
@Pipe({
  name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
  transform(value: string): string {
    return Autolinker.link(value, { stripPrefix: false });
  }
}
