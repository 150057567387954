/* eslint-disable @typescript-eslint/naming-convention */
import { Person } from '@app/_interfaces/person.interface';

export class Post {
  id: number;
  content: string;
  user: Person;
  created_at: string;
  comments: number;
  dislikes: number;
  disliked: boolean;
  likes: number;
  liked: boolean;
  type?: string;
  visibility: string;
  images: any[];
  in_bookmark: boolean;
  tags?: any;
  rating?: number;
  data?: Post;

  constructor(data?: any) {
    Object.assign(this, data);
  }
}
