import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@app/_models';
import { BaseComponent } from './_components/base.component';
import { IsoCountry } from './_interfaces/iso-country.interface';
import { SortPipe } from './_pipes/sort.pipe';
import { SseService, StorageService, UserService } from './_services';
import { AuthService } from './_services/auth/auth.service';
import { CommonService } from './_services/common.service';


@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent extends BaseComponent {

  user: User = null;
  lang: string = 'pl';

	constructor(
    private authService: AuthService,
    public storage: StorageService,
    private common: CommonService,
    private sseService: SseService,
    private userService: UserService,
    private sortPipe: SortPipe,
    private router: Router,
    private zone: NgZone,
    translate: TranslateService
  ) {
    super();
    this.initializeApp();
    // TODO: create lang service top manage lang change and initialization
    translate.setDefaultLang(this.lang);
    translate.use(this.lang);

    let sub;
    this.subscriptions.add(this.userService.statusPingShouldSubscribe.pipe(distinctUntilChanged()).subscribe(statusPingSubscribe => {
      if (statusPingSubscribe) {
        sub = this.sseService.syncUserStatusPing(this.storage.user.id).subscribe();
        this.subscriptions.add(sub);
        this.subscriptions.add(this.sseService.userPing$.subscribe(userActive =>
          this.userService.statusPong(userActive.owner_id).subscribe()));
      } else {
        sub?.unsubscribe();
      }
    }));

    this.getCountries();
  }

  isLoggedIn() {
    if (this.authService.userValue && this.authService.tokenValue) {
      this.user = new User(this.storage.user);
      this.userService.statusPingShouldSubscribe.next(true);
      return true;
    }
    return false;
  }

  getCountries() {
    const sub = this.common.getCountriesByLang().subscribe(async (countries: IsoCountry[]) => {
      countries = await this.sortPipe.transform(countries, 'asc', 'dial_code');

      const PL = countries.find(item => item.code === 'PL');
      countries = countries.filter(item => item.code !== 'PL');
      countries[0] = PL;
      this.storage.put('countries', JSON.stringify(countries));
    });
    this.subscriptions.add(sub);
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
          // Example url: https://beerswift.app/tabs/tab2
          // slug = /tabs/tab2
          const slug = event.url.split('.com').pop();
          if (slug) {
              this.router.navigateByUrl(slug);
          }
          // If no match, do nothing - let regular routing
          // logic take over
      });
    });
  }
}
