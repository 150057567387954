/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BaseRequestComponent } from '@app/_components/base-request.component';
import { FILE_TYPE } from '@app/_enums/file-type.enum';
import { FileResponse } from '@app/_models/file-response';
import { File } from '@app/_models/file.model';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '.';
import { InvestmentsService } from './investments.service';

@Injectable()
export class FileManagerService extends BaseRequestComponent {

    public fileSubject = new Subject<{action: string, file: File}>();
    public fileSubject$ = this.fileSubject.asObservable();

    constructor(
      private api: ApiService,
      private investmentService: InvestmentsService,
      private fileOpener: FileOpener) {
      super();
    }

    getFileManagerToken(id: number) {
      const sub = this.investmentService.getRootFolderUuid(id).subscribe((response: {token: string}) => {
        this.storage.put('root-dir-token', JSON.stringify(response));
        this.fileSubject.next({action: 'token-retrieved', file: null});
      }, (error) => {
        if (this.errorService.showMessage(error)) {
          this.notifications.onError(this.translate.instant('ERRORS.cant_get_root_dir_token'));
        }
      });
      this.subscriptions.add(sub);
    }

    markAsImportant(file: File, important: boolean, id: number) {
      const data = { important };
      const sub = this.markFileAsImportant(file.token, data, id).subscribe(_ => {
          file.important = important;
      }, error => {
          if (this.errorService.showMessage(error)) {
              this.notifications.onError(this.translate.instant(`ERRORS.cant_mark_file_as_${important ? 'important' : 'unimportant'}`));
          }
      });
      this.subscriptions.add(sub);
    }

    remove(file: File, id: number) {
      const sub = this.removeFileOrDirectory(file.token, id).subscribe(_ => {
          this.fileSubject.next({action: 'file-removed', file});
      }, error => {
          if (this.errorService.showMessage(error)) {
              this.notifications.onError(this.translate.instant(`ERRORS.cant_remove`));
          }
      });
      this.subscriptions.add(sub);
    }

    createNewDirectory(name: string, token: string, id: number) {
      const data = {
        name,
        important: false
      };
      const sub = this.createDirectory(token, data, id).subscribe((response: {token: string}) => {
        const directory = {
          name: data.name,
          important: data.important,
          type: FILE_TYPE.CATALOG,
          token: response.token,
          created_at: new Date(),
        };
        const file = new File(directory);
        this.fileSubject.next({action: 'directory-created', file});
      }, error => {
        if (this.errorService.showMessage(error)) {
          this.notifications.onError(this.translate.instant('ERRORS.cant_create_directory'));
        }
      });
      this.subscriptions.add(sub);

    }

    changeName(file: File, name: string, id: number) {
      const data = { name };
      const sub = this.renameFileOrDirectory(file.token, data, id).subscribe(_ => {
        file.name = name;
      }, error => {
        if (this.errorService.showMessage(error)) {
          this.notifications.onError(this.translate.instant(`ERRORS.cant_rename_${file.type}`));
        }
      });
      this.subscriptions.add(sub);
    }

    uploadNewFile(data: string, token: string, id: number) {
      const fd = new FormData();
      const blob = this.common.dataURItoBlob(data);
      const fileName = this.common.generateFileName() + '.png';
      fd.append('file', blob, fileName);

      if (this.common.fileSizeHasBeenExceeded(blob.size)) {
        this.notifications.onError(this.translate.instant(`ERRORS.max_upload_file_size`));
        return;
      }

      if (this.common.fileTypeNotAllowed(blob.type)) {
        this.notifications.onError(this.translate.instant(`ERRORS.not_allowed_file_type`));
        return;
      }

      const sub = this.uploadFile(token, fd, id).subscribe((response: {token: string}) => {
          const file = new File({
            name: fileName,
            token: response.token,
            created_at: new Date(),
            type: FILE_TYPE.FILE,
            important: false,
            thumbnail_url: this.common.isImageFileType(blob.type) ? URL.createObjectURL(blob) : null
          });
          this.fileSubject.next({action: 'file-uploaded', file});
      }, error => {
          if (this.errorService.showMessage(error)) {
            this.notifications.onError(this.translate.instant(`ERRORS.cant_upload_file`));
          }
      });
      this.subscriptions.add(sub);
    }

    uploadMultipleFiles(files: any, token: string, id: number) {
      if (files.length === 0) {
        return;
      }

      const fd = new FormData();
      fd.append('file', files[0], files[0].name);

      if (this.common.fileSizeHasBeenExceeded(files[0].size)) {
        this.notifications.onError(this.translate.instant(`ERRORS.max_upload_file_size`));
        return;
      }

      if (this.common.fileTypeNotAllowed(files[0].type)) {
        this.notifications.onError(this.translate.instant(`ERRORS.not_allowed_file_type`));
        return;
      }

      if (this.common.isImageFileType(files[0].type)) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          const blob = this.common.dataURItoBlob(reader.result);
          const thumb = URL.createObjectURL(blob);

          this.startUpload(fd, token, thumb, files[0].name, id);
        }, false);
        reader.readAsDataURL(files[0]);
      } else {
        this.startUpload(fd, token, null, files[0].name, id);
      }
    }

    async startUpload(fd: FormData, token: string, thumb: string, name: string, id: number) {
      await this.loadingService.presentLoading();
      const sub = this.uploadFile(token, fd, id).subscribe(async (event: any) => {
        await this.loadingService.dismiss();
        const file = new File({
          name,
          token: event.token,
          created_at: new Date(),
          type: FILE_TYPE.FILE,
          important: false,
          thumbnail_url: thumb
        });
        this.fileSubject.next({action: 'file-uploaded', file});
      }, async error => {
          await this.loadingService.dismiss();
          if (this.errorService.showMessage(error)) {
            this.notifications.onError(this.translate.instant(`ERRORS.cant_upload_file`));
          }
      });
      this.subscriptions.add(sub);
    }

    async confirmRemoveAlert(file: File, id: number) {
      const alert = await this.alertCtrl.create({
        header: this.translate.instant(`FILES.remove_${file.type}_title`),
        message: this.translate.instant(`FILES.remove_${file.type}_info`),
        buttons: [
          {
            text: this.translate.instant('APP.discard'),
          },
          {
            text: this.translate.instant('APP.remove'),
            handler: () => {
              this.remove(file, id);
            }
          }
        ]
      });
      await alert.present();
    }

    openOrDownloadFile(file: File, id: number) {
      const sub = this.getFileContent(file.token, id).subscribe(async event => {
        const name = event.name;
        const base64 = event.base64 as string;
        const mimeType = event.mime_type;

        if (await this.common.isWeb()) {
          const blob = this.common.dataURItoBlob(base64);
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = name;
          document.body.appendChild(link);
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );
          document.body.removeChild(link);
        } else {
          const savedFile = await Filesystem.writeFile({
            path: name,
            data: base64,
            directory: Directory.Documents
          });
          const path = savedFile.uri;
          this.openFile(path, mimeType);
        }
      });
      this.subscriptions.add(sub);
    }

    openFile(path: string, mimeType: string) {
      this.fileOpener.showOpenWithDialog(path, mimeType)
        .then(_ => this.notifications.onSuccess(this.translate.instant('FILES.file_saved_on_a_device')))
        .catch(e => console.log('Error opening file', e));
    }

    searchFiles(search: string, id: number) {
      const sub = this.findFiles(search, id).subscribe((response: File[]) => {
        const files = [];
        if (response.length) {
          for (const f of response) {
            files.push(new File(f));
          }
        }
        // @ts-ignore
        this.fileSubject.next({action: 'files-found', file: files});
      }, (error) => {
        if (this.errorService.showMessage(error)) {
          this.notifications.onError(this.translate.instant(`ERRORS.cant_find_files`));
        }
      });
      this.subscriptions.add(sub);
    }

    // API CALLS
    showDirectory(token: string, id: number): Observable<FileResponse> {
      return this.api.get(`file-manager/investment/${id}/directories/${token}`).pipe(map(response => response as FileResponse));
    }

    findFiles(search: string, id: number): Observable<File[]> {
      return this.api.get(`file-manager/investment/${id}/find-file`, { search }).pipe(map(response => response as File[]));
    }

    createDirectory(token: string, data: { name: string, important: boolean }, id: number): Observable<{ token: string }> {
      return this.api.post(`file-manager/investment/${id}/directories/${token}`, data).pipe(map(response => response as { token: string }));
    }

    markFileAsImportant(token: string, data: { important: boolean }, id: number): Observable<any> {
      return this.api.put(`file-manager/investment/${id}/important/${token}`, data);
    }

    removeFileOrDirectory(token: string, id: number): Observable<any> {
      return this.api.delete(`file-manager/investment/${id}/remove/${token}`);
    }

    renameFileOrDirectory(token: string, data: {name: string}, id: number): Observable<any> {
      return this.api.put(`file-manager/investment/${id}/rename/${token}`, data);
    }

    uploadFile(token: string, data: any, id: number): Observable<{ token: string }> {
      return this.api.post(`file-manager/investment/${id}/files/${token}`, data);
    }

    getFileContent(token: string, id: number) {
      return this.api.get(`file-manager/investment/${id}/files/${token}`);
    }

}
