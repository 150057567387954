import { Component, OnInit } from '@angular/core';
import { CommonService } from '@app/_services/common.service';

@Component({
  selector: 'app-register-completed',
  templateUrl: './register-completed.component.html',
  styleUrls: ['./register-completed.component.css']
})
export class RegisterCompletedComponent implements OnInit {

  constructor(public common: CommonService) { }

  ngOnInit() {
  }

}
