import { Injectable } from '@angular/core';
import { InvestmentLocalization } from '@app/_interfaces/investment-localization.interface';
import { Subject } from 'rxjs';

@Injectable()
export class MapService {
  public mapChanged = new Subject<InvestmentLocalization[]>();
	public mapChanged$ = this.mapChanged.asObservable();

  constructor() {
  }

  async refresh(investments: InvestmentLocalization[]) {
    this.mapChanged.next(investments);
  }
}
