import { Injectable } from '@angular/core';
import { SnotifyPosition, SnotifyService, SnotifyToastConfig, SnotifyType } from 'ng-snotify';

@Injectable({
	providedIn: 'root'
})
export class NotificationsService {
	timeout = 3000;
	position: SnotifyPosition = SnotifyPosition.centerTop;
	progressBar = false;
	closeClick = true;
	newTop = true;
	filterDuplicates = false;
	backdrop = -1;
	dockMax = 8;
	blockMax = 6;
	pauseHover = true;
	titleMaxLength = 15;
	bodyMaxLength = 80;
	icon: '';
	html: true;

	constructor(public snotifyService: SnotifyService) {
	}

	getConfig(type: SnotifyType): SnotifyToastConfig {
		this.snotifyService.setDefaults({
			global: {
				newOnTop: this.newTop,
				maxAtPosition: this.blockMax,
				maxOnScreen: this.dockMax,
				filterDuplicates: this.filterDuplicates
			}
		});
		return {
			bodyMaxLength: this.bodyMaxLength,
			titleMaxLength: this.titleMaxLength,
			backdrop: this.backdrop,
			position: this.position,
			timeout: this.timeout,
			showProgressBar: this.progressBar,
			closeOnClick: this.closeClick,
			pauseOnHover: this.pauseHover,
			icon: this.icon,
			type
		};
	}

	onSuccess(body: string) {
		const template = `
			<div class="notification notification-success">
				<div class="notification-icon">
          <img src="assets/img/checkmark-circle.png">
        </div>
				<div class="notification-body">${body}</div>
			</div>`;
		this.snotifyService.html(template, this.getConfig('success'));
	}

	onInfo(body: string) {
    const template = `
			<div class="notification notification-info">
        <div class="notification-icon">
        <img src="assets/img/information-circle.png">
        </div>
        <div class="notification-body">${body}</div>
			</div>`;
		this.snotifyService.html(template, this.getConfig('info'));
	}

	onError(body: string) {
		const template = `
			<div class="notification notification-error">
        <div class="notification-icon">
        <img src="assets/img/close-circle.png">
        </div>
        <div class="notification-body">${body}</div>
			</div>`;
		this.snotifyService.html(template, this.getConfig('error'));
	}

	onWarning(body: string) {
    const template = `
			<div class="notification notification-info">
        <div class="notification-icon">
        <img src="assets/img/information-circle.png">
        </div>
        <div class="notification-body">${body}</div>
			</div>`;
		this.snotifyService.html(template, this.getConfig('warning'));
	}
}
