import { Injectable } from '@angular/core';
import { LoadingService } from '@app/_services/loading.service';
import { Camera, CameraResultType, ImageOptions, Photo } from '@capacitor/camera';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';


@Injectable()
export class CropperService {
  public imageRetrieved = new Subject<string>();
	public imageRetrieved$ = this.imageRetrieved.asObservable();

  options: ImageOptions = {
    quality: 90,
    allowEditing: false,
    resultType: CameraResultType.DataUrl,
    saveToGallery: false
  };

  constructor(private loadingService: LoadingService, private translate: TranslateService) {}

  async takePicture() {
    this.options.promptLabelPhoto = this.translate.instant('SETTINGS.take_from_device');
    this.options.promptLabelPicture = this.translate.instant('SETTINGS.take_photo');
    await Camera.getPhoto(this.options)
      .then((photo: Photo) => {
        this.imageRetrieved.next(photo.dataUrl);
        this.loadingService.dismiss();
      }).catch(_ => {
        this.imageRetrieved.next(null);
        this.loadingService.dismiss();
      });
  }
}
