import { Injectable } from '@angular/core';
import { Role } from '@app/_models';
import { AuthService } from './auth/auth.service';

@Injectable({
	providedIn: 'root'
})
export class RoleService {
  constructor(private auth: AuthService) {}

  getRole() {
    if (!this.auth.userValue) {
      return false;
    }
    return `_${this.auth.userValue.role === Role.Investor ? 'RI' : 'RB'}`;
  }

  isBuilder() {
    if (!this.auth.userValue) {
      return false;
    }
    return this.auth.userValue.role === Role.Builder;
  }

  isInvestor() {
    if (!this.auth.userValue) {
      return false;
    }
    return this.auth.userValue.role === Role.Investor;
  }
}
