import { ActivatedRoute, Router } from '@angular/router';
import { FiltersParams } from '@app/_models/filters-params.model';
import { AppInjector } from '@app/_services/app-injector.service';

export class UrlController {
    router: Router;
    activatedRoute: ActivatedRoute;

    constructor() {
        const injector = AppInjector.getInjector();
        this.router = injector.get(Router);
        this.activatedRoute = injector.get(ActivatedRoute);
    }

    updateUrlParams(params: FiltersParams) {
        const paramsForBrowserUrl: any = {};
        for (const k in params) {
            if (k === 'api_url' || k === 'page' || k === 'filters_user' || (Array.isArray(params[k]) && !params[k].length)) {
                continue;
            }

            if (Array.isArray(params[k])) {
                if (params[k].every(e => typeof e === 'string' || typeof e === 'number')) {
                    paramsForBrowserUrl[k] = params[k].join(',');
                } else if (params[k].every(e => typeof e === 'object')) {
                    paramsForBrowserUrl[k] = params[k].map(e => JSON.stringify(e));
                }
            } else if (!Array.isArray(params[k]) && typeof params[k] === 'object') {
                paramsForBrowserUrl[k] = params[k].toString();
            } else if (params[k] !== '') {
                paramsForBrowserUrl[k] = params[k];
            }
        }
        this.router.navigate([], {
            queryParams: paramsForBrowserUrl
        });
    }

    getParamsFromUrl() {
        const params = {};
        this.activatedRoute.snapshot.queryParamMap.keys.forEach((key: string) => {
            const paramVal = this.activatedRoute.snapshot.queryParamMap.get(key);
            if (paramVal.indexOf(',') > -1 && paramVal.indexOf('{') < 0) {
                params[key] = paramVal.split(',');
            } else {
                if (key === 'page') {
                    params[key] = +paramVal;
                } else {
                    params[key] = paramVal;
                }
            }
        });
        return params;
    }
}
