import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@app/_components/base.component';
import { CommonService, UserService } from '@app/_services';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './pages.component.html'
})
export class PagesComponent extends BaseComponent {

  content = '';

  private urlMap = {
    '/terms-of-service': 'getTos',
    '/privacy-policy': 'getPrivacyPolicy'
  };

  constructor(
    public common: CommonService,
    private router: Router,
    private userService: UserService) {
    super();
    this.subscriptions.add(
      this.userService[this.urlMap[this.router.url]]().subscribe(page => this.content = page.content)
    );
  }
}
