import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { BaseComponent } from '@app/_components/base.component';
import { regex } from '@app/_helpers/regex';
import { CommonService } from '@app/_services/common.service';
import { ErrorService } from '@app/_services/error.service';
import { LoadingService } from '@app/_services/loading.service';
import { UserService } from '@app/_services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {

  resetForm: FormGroup;
	loading = false;

  validationMessages = {
    email: [
      { type: 'required', message: 'field_required'},
      { type: 'pattern', message: 'invalid_email'}
    ]
  };

  constructor(
    public common: CommonService,
    public errorService: ErrorService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private router: Router,
    private userService: UserService
    ) {
      super();
  }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(regex.email)
      ])],
    });
  }

  get f() {
		return this.resetForm.controls;
	}

  async onSubmit() {
    this.loadingService.presentLoading();
    const sub = this.userService.getResetPasswordLink({email: this.f.email.value}).subscribe(_ => {
      this.loadingService.dismiss();
      const navigationExtras: NavigationExtras = {
        replaceUrl: true,
        state: {
          email: this.f.email.value
        }
      };
      this.resetForm.reset();
      this.router.navigate(['/forgot-password/completed'], navigationExtras);
    }, (error) => {
      this.loadingService.dismiss();
    });
    this.subscriptions.add(sub);
  }

}
