/* eslint-disable max-len */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, NgZone } from '@angular/core';
import { CHAT_TOPIC } from '@app/_enums/chat-topic.enum';
import { environment } from '@environments/environment';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SseService {

  public message = new Subject<any>();
  public message$ = this.message.asObservable();

  public notification = new Subject<any>();
  public notification$ = this.notification.asObservable();

  public userPing = new Subject<any>();
  public userPing$ = this.userPing.asObservable();

  public userPong = new Subject<any>();
  public userPong$ = this.userPong.asObservable();

  eventSources: Map<number, EventSourcePolyfill> = new Map();

  chatSynced: Set<number> = new Set();

  constructor(private zone: NgZone, private auth: AuthService) {}

  syncMessages(type: CHAT_TOPIC, id: number = null) {
    const url = new URL(environment.mercureHubUrl);
    this.buildMessagesTopicUrls(type, id).map(
      topicUrl => url.searchParams.append('topic', topicUrl)
    );
    this.chatSynced.add(id);

    return this.getServerSentEvent(`${url}`, id).pipe(
      tap(event => {
        const data = {...JSON.parse(event.data), chatId: id};
        this.message.next(data);
      }),
    );
  }

  syncNotifications() {
    const url = new URL(environment.mercureHubUrl);
    url.searchParams.append('topic', this.buildNotificationsTopicUrls());

    return this.getServerSentEvent(`${url}`, this.auth.userValue.id).pipe(
      tap(event => {
        const data = JSON.parse(event.data);
        this.notification.next(data);
      }),
    );
  }

  syncUserStatusPing(id: number) {
    const url = new URL(environment.mercureHubUrl);
    url.searchParams.append('topic', this.buildUserActiveTopicUrls(id));

    return this.getServerSentEvent(`${url}`, this.auth.userValue.id, true).pipe(
      tap(event => {
        const data = JSON.parse(event.data);
        this.userPing.next(data);
      }),
    );
  }

  syncUserStatusPong(id: number) {
    const url = new URL(environment.mercureHubUrl);
    url.searchParams.append('topic', this.buildUserActiveTopicUrls(id, false));

    return this.getServerSentEvent(`${url}`, this.auth.userValue.id, true).pipe(
      tap(event => {
        const data = JSON.parse(event.data);
        this.userPong.next(data);
      }),
    );
  }

  buildMessagesTopicUrls(type: CHAT_TOPIC, id: number) {
    const baseUrl = environment.baseUrl;
    const userToken = this.auth.userValue.token;

    return [
      `${baseUrl}/users/${userToken}/?topic=${encodeURIComponent(`${baseUrl}/${type}/`)}${id}`,
      `${baseUrl}/users/${userToken}/?topic=${encodeURIComponent(`${baseUrl}/chat/${id}/message/deleted`)}`
    ];
  }

  buildNotificationsTopicUrls() {
    const baseUrl = environment.baseUrl;
    const userToken = this.auth.userValue.token;

    return `${baseUrl}/users/${userToken}/?topic=${encodeURIComponent(`${baseUrl}/notifications/added`)}`;
  }

  buildUserActiveTopicUrls(id: number, query = true) {
    const baseUrl = environment.baseUrl;
    const userToken = this.auth.userValue.token;

    return `${baseUrl}/users/${userToken}/?topic=${encodeURIComponent(`${baseUrl}/users/${id}/${query ? 'ping' : 'pong'}`)}`;
  }

  getServerSentEvent(url: string, id, force = false): Observable<MessageEvent> {

    return new Observable(observer => {
      if (force || !this.eventSources.get(id)) {
        this.eventSources.set(id, this.getEventSource(url));

        this.eventSources.get(id).onmessage = event => {
          this.zone.run(() => observer.next(event));
        };

        this.eventSources.get(id).onerror = () => {
          //TODO: zrobić obsługe nieaktywności
        };
      }
    });
  }

  disconnect() {
    if (this.eventSources?.size) {
      this.eventSources.forEach((eventSource, key) => {
        if (key !== this.auth.userValue.id) {
          eventSource.close();
        }
      });
      this.eventSources.clear();
    }
    this.chatSynced.clear();
  }

  private getEventSource(url: string): EventSourcePolyfill {
    return new EventSourcePolyfill(url, {
      headers: {
        Authorization: 'Bearer ' + this.auth.mercureTokenValue.token,
      }
    });
  }
}
