/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BaseComponent } from '@app/_components/base.component';
import { ContactCategory } from '@app/_interfaces/contact-category.interface';
import { IsoCountry } from '@app/_interfaces/iso-country.interface';
import { LastCall } from '@app/_interfaces/last-call.interface';
import { Response } from '@app/_models';
import { Contact } from '@app/_models/contact.model';
import { Investment } from '@app/_models/investment.model';
import { TableResponse } from '@app/_models/table-response.model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, StorageService } from '.';
import { LoadingService } from './loading.service';

@Injectable()
export class ContactsService extends BaseComponent {

  public contactUpdated = new Subject<Contact | {error: string}>();
  public contactUpdated$ = this.contactUpdated.asObservable();

  public contactRemoved = new Subject<number>();
  public contactRemoved$ = this.contactRemoved.asObservable();

  public contactAdd = new Subject<any>();
  public contactAdd$ = this.contactAdd.asObservable();

  public contactRefresh = new Subject<boolean>();
  public contactRefresh$ = this.contactRefresh.asObservable();

  constructor(
    private api: ApiService,
    private loadingService: LoadingService,
    private storage: StorageService
    ) {
      super();
    }

  async update(id: number, contact) {
    await this.loadingService.presentLoading();

    const category: ContactCategory = contact.category;
    const country: IsoCountry = contact.country;
    const prefix: IsoCountry = contact.prefix;

    contact.category = this.getCategory(category);
    contact.country = this.getCountry(country);
    contact.prefix = this.getPrefix(prefix);

    const sub = this.updateContact(this.storage.user.id, id, contact).subscribe(async _ => {
      contact.category = category;
      contact.country = country;
      contact.prefix = prefix;
      contact.id = id;
      this.contactUpdated.next(contact);
      await this.loadingService.dismiss();
    }, async _ => {
      this.contactUpdated.error({error: 'cant_update_contact'});
      await this.loadingService.dismiss();
    });
    this.subscriptions.add(sub);
  }

  async add(contact) {
    await this.loadingService.presentLoading();

    const category: ContactCategory = contact.category;
    const country: IsoCountry = contact.country;
    const prefix: IsoCountry = contact.prefix;

    contact.category = this.getCategory(category);
    contact.country = this.getCountry(country);
    contact.prefix = this.getPrefix(prefix);

    const sub = this.addContact(this.storage.user.id, contact).subscribe(async (data: Contact) => {
      data.category = category;
      data.country = country;
      data.prefix = prefix;
      this.contactUpdated.next(data);
      await this.loadingService.dismiss();
    }, async _ => {
      this.contactUpdated.error({error: 'cant_add_contact'});
      await this.loadingService.dismiss();
    });
    this.subscriptions.add(sub);
  }

  getCountry(country) {
    return country ? (typeof country === 'object' ? country.code : country) : null;
  }

  getPrefix(prefix) {
    return prefix ? (typeof prefix === 'object' ? prefix.dial_code : prefix) : null;
  }

  getCategory(category) {
    return category ? category.id : null;
  }

  openAddContactModal() {
    this.contactAdd.next(true);
  }

  firstLetter(name: string, format: string) {
    if (format === 'lower') {
      return name.substr(0, 1).toLowerCase();
    }
    return name.substr(0, 1).toUpperCase();
  }


  // API CALLS

  addContact(id: number, data: Contact): Observable<Contact> {
    return this.api.post(`users/${id}/contacts`, data).pipe(map(response => response as Contact));
  }

  updateContact(id: number, contact_id: number, data: Contact) {
    return this.api.put(`users/${id}/contacts/${contact_id}`, data).pipe(map(response => response));
  }

  attachContactsToInvestment(id: number, contacts: number[]) {
    return this.api.put(`investments/${id}/contacts`, { contacts }).pipe(map(response => response));
  }

  updateContactCategory(id: number, data: {name: string}) {
    return this.api.put(`contact-categories/${id}`, data);
  }

  removeContact(id: number, contact_id: number) {
    return this.api.delete(`users/${id}/contacts/${contact_id}`);
  }

  detachContactFromInvestment(id: number, contact_id: number) {
    return this.api.delete(`users/${id}/contacts/${contact_id}`);
  }

  deleteContactCategory(id: number) {
    return this.api.delete(`contact-categories/${id}`);
  }

  getContacts(id: number, type: string, investmentId: number = null): Observable<Response<Contact[]>> {
    let url = '';
    switch (type) {
      case 'main-contacts':
        url = `users/${id}/contacts`;
        break;
      case 'investment-contacts':
        url = `investments/${investmentId}/contacts`;
        break;
    }
    return this.api.get(url).pipe(map(response => response as Response<Contact[]>));
  }

  showContact(id: number, contact_id: number): Observable<Contact> {
    return this.api.get(`users/${id}/contacts/${contact_id}`).pipe(map(response => response as Contact));
  }

  getContactCategories(): Observable<ContactCategory[]> {
    return this.api.get(`contact-categories`).pipe(map(response => response as ContactCategory[]));
  }

  getLastCalls(params: any = {}): Observable<TableResponse<LastCall[]>> {
    return this.api.get(`users/contacts-history`, params).pipe(map(response => response as TableResponse<LastCall[]>));
  }

  getContactInvestments(id: number): Observable<TableResponse<Investment[]>> {
    return this.api.get(`contacts/${id}/investments`).pipe(map(response => response as TableResponse<Investment[]>));
  }

  registerContact(id: number, data) {
    return this.api.put(`users/contacts/${id}/select`, data);
  }

  addContactCategory(data: {name: string}): Observable<{id: number}> {
    return this.api.post(`contact-categories`, data).pipe(map(response => response as {id: number}));
  }
}
