import { FILE_TYPE } from '@app/_enums/file-type.enum';

/* eslint-disable @typescript-eslint/naming-convention */
export class File {
  name: string;
  ext: string;
  size: number;
  created_at: string;
  thumbnail_url: string;
  important: boolean;
  locked: boolean;
  token: string;
  type: FILE_TYPE;

  videoTypes: string[] = ['.mp4', '.mpeg', '.avi'];

  constructor(data?: any) {
    Object.assign(this, data);
  }

  get videoType() {
    return this.videoTypes.indexOf(this.ext) !== -1;
  }

  get isLocked() {
    return this.locked;
  }

  get isDirectory() {
    return this.type === FILE_TYPE.CATALOG;
  }

  get fileThumb() {
    return this.thumbnail_url ? `url(${this.thumbnail_url})` : null;
  }
}
