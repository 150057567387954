import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export class BaseComponent implements OnDestroy {

  subscriptions: Subscription = new Subscription();

  constructor() {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
