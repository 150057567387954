/* eslint-disable @typescript-eslint/naming-convention */
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseRequestComponent } from '@app/_components/base-request.component';
import { PasswordValidator } from '@app/_helpers/password.validator';
import { regex } from '@app/_helpers/regex';
import { Role } from '@app/_models';
import { AuthService } from '@app/_services/auth/auth.service';
import { CommonService } from '@app/_services/common.service';
import { ErrorService } from '@app/_services/error.service';
import { UserService } from '@app/_services/user.service';
import { IonSelect, ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PrivacyModalComponent } from '../components/privacy-modal/privacy-modal.component';
import { RegulationsModalComponent } from '../components/regulations-modal/regulations-modal.component';
import { environment } from 'src/environments/environment';

export interface Profession {
  slug: string;
  name: string;
}

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends BaseRequestComponent implements OnInit, OnDestroy {

  @ViewChild('professionSelect', { static: false }) professionSelect: IonSelect;
  unsubscribe$: Subject<any> = new Subject();

  registrationForm: FormGroup;
  matchingPasswordsGroup: FormGroup;

  selectedProfession: Profession;
  professions: Profession[] = [];

  user = JSON.parse(this.storage.get('user'));
  idpPath = false;

  validationMessages = {
    password: [
      { type: 'required', message: 'field_required'},
      { type: 'minlength', message: 'min_length'},
      { type: 'pattern', message: 'one_number_and_one_capital'},
    ],
    confirm_password: [
      { type: 'required', message: 'field_required'},
    ],
    matching_passwords: [
      { type: 'areEqual', message: 'not_equal'},
    ],
    first_name: [
      { type: 'required', message: 'field_required'},
      { type: 'minlength', message: 'min_length_2'}
    ],
    profession: [
      { type: 'required', message: 'field_required'},
    ],
    last_name: [
      { type: 'required', message: 'field_required'},
      { type: 'minlength', message: 'min_length_2'}
    ],
    email: [
      { type: 'required', message: 'field_required'},
      { type: 'pattern', message: 'invalid_email'},
    ],
    accept_terms_of_service: [
      { type: 'required', message: 'field_required'},
    ]
  };
  role: string;
  token: string = null;
  tokenIDP = {};
  isDisabled: boolean = false;

	constructor(
      public authService: AuthService,
      public errorService: ErrorService,
      public common: CommonService,
      private route: ActivatedRoute,
      private formBuilder: FormBuilder,
      private userService: UserService,
      private modalController: ModalController
    ) {
    super();
	}

  @HostListener('window:userInStorage') onResizeEvent() {
    this.user = JSON.parse(this.storage.get('user'));
    this.checkUser();
  };

  get f() {
		return this.registrationForm.controls;
	}

	ngOnInit() {
    this.matchingPasswordsGroup = new FormGroup({
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(regex.password),
      ])),
      confirm_password: new FormControl('', Validators.required)
    }, (formGroup: FormGroup) => PasswordValidator.areEqual(formGroup));

    this.registrationForm = this.formBuilder.group({
      matching_passwords: this.matchingPasswordsGroup,
      first_name: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      last_name: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(regex.email)
      ])],
      accept_terms_of_service: [false, Validators.requiredTrue],
      accept_marketing_terms: [false]
    });

    this.checkUser();

    const sub = this.route.queryParams.subscribe(params => {
      if (params.invitation_token && params.account_type && params.email) {
        this.role = params.account_type === 'investor' ?  Role.Investor : Role.Builder;
        this.checkRole();
        this.token = params.invitation_token;
        this.registrationForm.get('email').setValue(params.email);
        this.isDisabled = true;
      } else if (this.router.getCurrentNavigation().extras.state) {
        this.role = this.router.getCurrentNavigation().extras.state.role;
        this.checkRole();
      } else if (this.isSingleStepRegistrationEnabled()) {
        this.role = Role.Investor;
        this.tokenIDP = JSON.parse(this.storage.get('tokenIDP'));
        this.checkRole();
      } else {
        this.router.navigate(['/register/select-account-type'], {replaceUrl: true});
      }
    });
    this.subscriptions.add(sub);
	}

  checkUser() {
    if (this.user) {
      this.idpPath = true;
      this.registrationForm.removeControl('matching_passwords');
      this.registrationForm.patchValue({ ...this.user, ...this.user.billing_address });
      setTimeout(() => this.registrationForm.markAllAsTouched());
    }
  }

  checkRole() {
    if (this.isBuilder() || this.isSingleStepRegistrationEnabled()) {
      this.getProfessions();

      this.registrationForm.addControl('profession', new FormControl('', [Validators.required]));
    } else {
      if (this.registrationForm.contains('profession')) {
        this.registrationForm.removeControl('profession');
      }
    }
  }

  async onSubmit() {
    const data = {
      role: this.role,
      invitation_token: this.token ? this.token : null,
      first_name: this.f.first_name.value,
      last_name: this.f.last_name.value,
      email: this.f.email.value,
      accept_terms_of_service: this.f.accept_terms_of_service.value,
      accept_marketing_terms: this.f.accept_marketing_terms.value
    };
    let registerMethod = 'register';
    if (this.tokenIDP) {
      // @ts-ignore
      data[`${this.tokenIDP?.platform}_token`] = this.tokenIDP?.token;
      registerMethod = 'registerIdentity';
    } else {
      // @ts-ignore
      data.password = this.registrationForm.get('matching_passwords').get('password').value;
    }
    if (this.isBuilder() || this.isSingleStepRegistrationEnabled()) {
      // @ts-ignore
      data.profession = this.f.profession.value.slug;
    } else {
      // @ts-ignore
      if (data.profession) {
        // @ts-ignore
        delete data.profession;
      }
    }
    await this.loadingService.presentLoading();
    const sub = this.userService[registerMethod](data).subscribe(async _ => {
      await this.loadingService.dismiss();
      this.registrationForm.reset();
      if (this.tokenIDP) {
        this.authService.login();
      } else {
        this.router.navigate(['/register/completed']);
      }
    }, async (error) => {
      await this.loadingService.dismiss();
      if (error.errors.email) {
        this.notifications.onError(this.translate.instant('ERRORS.user_email_exists'));
      }
    });
    this.subscriptions.add(sub);
  }

  async showRegulationsModal() {
    const modal = await this.modalController.create({
      component: RegulationsModalComponent
    });
    return await modal.present();
  }

  async showPrivacyPolicyModal() {
    const modal = await this.modalController.create({
      component: PrivacyModalComponent
    });
    return await modal.present();
  }

  getProfessions() {
    this.userService.getProfessions().pipe(takeUntil(this.unsubscribe$)).subscribe((response: Profession[]) => {
      this.professions = response;
    }, error => {
      if (this.errorService.showMessage(error)) {
        this.notifications.onError(this.translate.instant(`ERRORS.cant_get_professions`));
      }
    });
  }

  isBuilder() {
    return this.role === Role.Builder;
  }

  isSingleStepRegistrationEnabled() {
    return environment?.features?.singleStepRegistration;
  }

  changeProfession(event) {
    this.selectedProfession = event.target.value;
  }

  ngOnDestroy() {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }
}
