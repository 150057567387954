import { Component, OnInit } from '@angular/core';
import { CommonService } from '@app/_services/common.service';

@Component({
  selector: 'app-reset-password-completed',
  templateUrl: './reset-password-completed.component.html',
  styleUrls: ['./reset-password-completed.component.css']
})
export class ResetPasswordCompletedComponent implements OnInit {

  constructor(public common: CommonService) { }

  ngOnInit() {
  }

}
