import { Pipe, PipeTransform } from '@angular/core';
import { DefaultAddress } from '@app/_interfaces/default-address.interface';

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
    transform(value: DefaultAddress): any {
      if (value) {
        return ``;
      }
    }
}
