import { FormGroup } from '@angular/forms';

export class ErrorService {
  hasError(form: FormGroup, field: any, validation) {
      if (form.get(field).hasError(validation.type) && (form.get(field).dirty || form.get(field).touched)) {
        const el = document.getElementById(`input[${field}]`);
        if (el) {
          el.classList.add('has-error');
        }
        return true;
      } else if (!form.get(field).invalid && form.get(field).touched) {
        const el = document.getElementById(`input[${field}]`);
        if (el) {
          el.classList.remove('has-error');
        }
        return false;
      }
  }

  isPasswordInvalid(form: FormGroup, control: string, field: string) {
    return form.controls[control].get(field).invalid && form.controls[control].get(field).dirty;
  }

  isPasswordTypeInvalid(form: FormGroup, control: string, field: string, type: string) {
    return form.get(control).get(field).hasError(type) && form.get(control).get(field).dirty;
  }

  isMatchingPasswordTypeInvalid(form: FormGroup, control: string, field: string, type: string) {
    return form.get(control).hasError(type) && form.get(control).get(field).dirty;
  }

  showMessage(error) {
    if (error) {
      if (error.code === 401 || error.code === 403 || error.code === 406) {
        return false;
      }
      return true;
    }
  }
}
