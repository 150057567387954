/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
export class FiltersParams {
  search?: string = '';

  filters_user?: string = '';
  filters_tab?: string = '';
  filters_type?: string = '';
  filters_priority?: string = '';
  filters_investment?: string = '';
  filters_profession?: string = '';
  filters_voivodeship?: string = '';
  filters_sort?: string = '';

  page?: string = '';
  page_last?: string = '';
  per_page?: string = '';
  order_by?: string = '';

  api_url: string = '';
  date?: string = '';

  constructor(data?: any) {
    data ? Object.assign(this, data) : null;
  }
}
