import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class LoadingService {

  constructor(public loadingController: LoadingController) {
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: '<ion-img src="/assets/img/logo.svg" alt="loading..."></ion-img>',
      spinner: null,
      duration: 10000,
      mode: 'md'
    });
    await loading.present();
  }

  async dismiss() {
    const overlay = await this.loadingController.getTop();
    if (overlay) {
      await this.loadingController.dismiss();
    }
  }
}
