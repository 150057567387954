/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@app/_components/base.component';
import { Role } from '@app/_models';
import { AuthService } from '@app/_services/auth/auth.service';
import { CommonService } from '@app/_services/common.service';
import { LoadingService } from '@app/_services/loading.service';
import { UserService } from '@app/_services/user.service';
import { ModalController } from '@ionic/angular';
import { PrivacyModalComponent } from '../components/privacy-modal/privacy-modal.component';
import { RegulationsModalComponent } from '../components/regulations-modal/regulations-modal.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-accept-regulations',
  templateUrl: './accept-regulations.component.html',
  styleUrls: ['./accept-regulations.component.scss']
})
export class AcceptRegulationsComponent extends BaseComponent implements OnInit {

  regulationsForm: FormGroup;
  role: string;

  constructor(
    public common: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private userService: UserService,
    private authService: AuthService,
    private modalController: ModalController,
    private router: Router) {
      super();
    }

  ngOnInit() {
    if (this.authService.userValue?.role !== Role.Guest) {
      this.router.navigate(['/'], {replaceUrl: true});
    }

    const sub = this.route.queryParams.subscribe(_ => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.role = this.router.getCurrentNavigation().extras.state.role;
      } else {
        this.router.navigate(
          [environment?.features?.singleStepRegistration ? '/register' : '/register/regulations/select-account-type'],
          {replaceUrl: true}
        );
      }
    });
    this.subscriptions.add(sub);

    this.regulationsForm = this.formBuilder.group({
      accept_terms_of_service: ['', Validators.requiredTrue],
      accept_marketing_terms: [false]
    });
  }

  get f() {
		return this.regulationsForm.controls;
	}

  async onSubmit() {
    const data = {
      role: this.role,
      accept_terms_of_service: this.f.accept_terms_of_service.value,
      accept_marketing_terms: this.f.accept_marketing_terms.value
    };
    await this.loadingService.presentLoading();
    const sub = this.userService.finalizeRegistration(this.authService.userValue.id, data).subscribe(async _ => {
      await this.loadingService.dismiss();
      this.authService.login();
    }, async () => {
      await this.loadingService.dismiss();
    });
    this.subscriptions.add(sub);
  }

  async showRegulationsModal() {
    const modal = await this.modalController.create({
      component: RegulationsModalComponent
    });
    return await modal.present();
  }

  async showPrivacyPolicyModal() {
    const modal = await this.modalController.create({
      component: PrivacyModalComponent
    });
    return await modal.present();
  }
}
