import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@app/_components/base.component';
import { PasswordValidator } from '@app/_helpers/password.validator';
import { regex } from '@app/_helpers/regex';
import { CommonService } from '@app/_services/common.service';
import { ErrorService } from '@app/_services/error.service';
import { LoadingService } from '@app/_services/loading.service';
import { UserService } from '@app/_services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {

  resetForm: FormGroup;
  matchingPasswordsGroup: FormGroup;
	loading = false;
  token: string;

  validationMessages = {
    password: [
      { type: 'required', message: 'field_required'},
      { type: 'minlength', message: 'min_length'},
      { type: 'pattern', message: 'one_number_and_one_capital'},
    ],
    confirmPassword: [
      { type: 'required', message: 'field_required'},
    ],
    matchingPasswords: [
      { type: 'areEqual', message: 'not_equal'},
    ]
  };

  constructor(
    public common: CommonService,
    public errorService: ErrorService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');

    this.matchingPasswordsGroup = new FormGroup({
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(regex.password),
      ])),
      confirmPassword: new FormControl('', Validators.required)
    }, (formGroup: FormGroup) => PasswordValidator.areEqual(formGroup));

    this.resetForm = this.formBuilder.group({
      matchingPasswords: this.matchingPasswordsGroup
    });
  }

  async onSubmit() {
    const data = {
      resetPasswordToken: this.token,
      newPassword: this.resetForm.get('matchingPasswords').get('password').value
    };
    this.loadingService.presentLoading();
    const sub = this.userService.resetPassword(data).subscribe(_ => {
      this.resetForm.reset();
      this.router.navigate(['/reset-password/completed'], {replaceUrl: true});
      this.loadingService.dismiss();
    }, () => {
      this.loadingService.dismiss();
    });
    this.subscriptions.add(sub);
  }

}
