import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@app/_components/base.component';
import { CommonService } from '@app/_services/common.service';
import { UserService } from '@app/_services/user.service';

@Component({
  selector: 'app-register-activate',
  templateUrl: './register-activate.component.html',
  styleUrls: ['./register-activate.component.scss']
})
export class RegisterActivateComponent extends BaseComponent implements OnInit {

  loading = false;

  constructor(
    public common: CommonService,
    private route: ActivatedRoute,
    private userService: UserService
    ) {
      super();
    }

  ngOnInit() {
    this.loading = true;
    const sub = this.userService.activateUserAccount(this.route.snapshot.paramMap.get('token')).subscribe(_ => {
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
    this.subscriptions.add(sub);
  }
}
