import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Post } from '@app/_models/post.model';
import { WallService } from '@app/_services/wall.service';
import { Observable } from 'rxjs';

@Injectable()
export class PostResolver implements Resolve<Post> {
  constructor(private wall: WallService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Post> {
    const id = +route.params.id;
    return this.wall.showPost(id);
  }
}
