/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Review, Token } from '@app/_interfaces';
import { AboutUserAndCompany } from '@app/_interfaces/about-user-and-company.interface';
import { DashboardStatistics } from '@app/_interfaces/dashboard-statistics.interface';
import { DateRange } from '@app/_interfaces/date-range.interface';
import { Expense } from '@app/_interfaces/expense.interface';
import { Note } from '@app/_interfaces/note.interface';
import { Person } from '@app/_interfaces/person.interface';
import { Realization } from '@app/_interfaces/realizations.interface';
import { UserAvatar } from '@app/_interfaces/user-avatar.interface';
import { UserTask } from '@app/_interfaces/user-task.interface';
import { Response, User } from '@app/_models';
import { TableResponse } from '@app/_models/table-response.model';
import { Profession } from '@app/_pages/auth/register/register.component';
import { ApiService } from '@app/_services';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class UserService {

  public quickTaskAdded = new Subject<boolean>();
	public quickTaskAdded$ = this.quickTaskAdded.asObservable();
	public userSubject = new Subject<User>();
	public profileSubject = new Subject<User>();
	public unseenNotificationCount = new BehaviorSubject<number>(0);
  public statusPingShouldSubscribe = new BehaviorSubject<boolean>(false);

	constructor(private api: ApiService) {
	}

  async taskAdded(value: boolean) {
    this.quickTaskAdded.next(value);
  }

	getUser(): Observable<User> {
		return this.api.get(`me`).pipe(map(response => response as User));
	}

	getCoworkers(): Observable<{used: number, limit: number}> {
		return this.api.get(`cooworkers-limit-state`).pipe(map(response => response as {used: number, limit: number}));
	}

	getTos(): Observable<any> {
		return this.api.get(`parameters/tos`);
	}

	getPrivacyPolicy(): Observable<any> {
		return this.api.get(`parameters/privacy-policy`);
	}

	getDashbaordSummary(): Observable<DashboardStatistics> {
		return this.api.get(`summary-blocks`).pipe(map(response => response as DashboardStatistics));
	}

  getProfessions(): Observable<Profession[]> {
    return this.api.get(`parameters/profession`).pipe(
      map(response => response.sort((a, b) => {
        const fa = a.name.toLowerCase();
        const fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      }) as Profession[])
    );
	}

	showProfile(id: number): Observable<User> {
		return this.api.get(`users/${id}`).pipe(map(response => response as User));
	}

	statusPing(id: number): Observable<User> {
		return this.api.post(`users/${id}/ping`, {}).pipe(map(response => response as User));
	}

  statusPong(id: number): Observable<User> {
		return this.api.post(`users/${id}/pong`, {}).pipe(map(response => response as User));
	}

	getObservers(id: number, type: string): Observable<any> {
		return this.api.get(`users/${id}/${type}`).pipe(map(response => response as any));
	}

	downloadInvoices(data: any, id: number): Observable<any> {
		return this.api.post(`users/${id}/expense/archive-token`, data).pipe(map(response => response as any));
	}

	getUserTasks(): Observable<UserTask[]> {
		return this.api.get(`users/tasks`).pipe(map(response => response as UserTask[]));
	}

  createUserExpense(id: number, data: any): Observable<Expense> {
    return this.api.post(`users/${id}/expense`, data).pipe(map(response => response as Expense));
  }

	getNotifications(type: string, params: any = {}): Observable<any> {
		return this.api.get(`profile/notifications/${type}`, params).pipe(map(response => response as any));
	}

  markAllNotifications(): Observable<any> {
    return this.api.put(`profile/notifications/mark-all`, {}).pipe(map(response => response as any));
  }

  uploadUserExpenseFile(id: number, expenseId: number, data: any): Observable<any> {
		return this.api.post(`users/${id}/expense/${expenseId}/image`, data).pipe(map(response => response as any));
	}

  addUserTask(data: any): Observable<any> {
		return this.api.post(`users/tasks`, data).pipe(map(response => response as any));
	}

	getUserExpenses(id: number, params: any = {}): Observable<any> {
		return this.api.get(`users/${id}/expense`, params).pipe(map(response => response as any));
	}

	getStatistics(period: string, type: string): Observable<any> {
		return this.api.get(`summary/${period}/${type}`).pipe(map(response => response as any));
	}

	getAboutUserAndCompany(id: number): Observable<AboutUserAndCompany> {
		return this.api.get(`users/${id}/about`).pipe(map(response => response as AboutUserAndCompany));
	}

  removeUserExpense(id: number, expenseId: number): Observable<any> {
    return this.api.delete(`users/${id}/expense/${expenseId}`);
  }

	getUserRealizations(id: number): Observable<TableResponse<Realization[]>> {
		return this.api.get(`users/${id}/realization`).pipe(map(response => response as TableResponse<Realization[]>));
	}

  getAllUsers(query: string): Observable<Response<Person[]>> {
		return this.api.get(`users/search?query=${query}`).pipe(map(response => response as Response<Person[]>));
	}

  updateUserExpense(id: number, expenseId: number, data: any): Observable<any> {
    return this.api.put(`users/${id}/expense/${expenseId}`, data);
  }

  getImportantNotes(params: any = {}): Observable<Note[]> {
		return this.api.get(`user/notes`, params).pipe(map(response => response as Note[]));
	}

  getFirstAvailableDay(id: number): Observable<{date: string}> {
		return this.api.get(`users/${id}/calendar-first-free-date`).pipe(map(response => response as {date: string}));
	}

  getUserPermissions(id: number, userId: number): Observable<any> {
		return this.api.get(`investments/${id}/permissions/${userId}`).pipe(map(response => response as any));
	}

	getMercureToken(): Observable<Token> {
		return this.api.get(`mercure/token`).pipe(map(response => response as Token));
	}

	getSchedule(id: number, params: any = {}): Observable<DateRange[]> {
		return this.api.get(`users/${id}/calendar`, params).pipe(
      map(response => response as DateRange[])
    );
	}

  updateUser(id: number, data: any): Observable<any> {
    return this.api.put(`users/${id}/info`, data).pipe(map(response => response as any));
  }

  setAllUserTasksAsCompleted(): Observable<any> {
    return this.api.put(`users/tasks/mark-all`, {});
  }

  updateUserAddress(data: any, type: string): Observable<any> {
    return this.api.put(`me/${type}-address`, data).pipe(map(response => response as any));
  }

  updateUserTask(token: string, data: any): Observable<any> {
    return this.api.put(`users/tasks/${token}`, data).pipe(map(response => response as any));
  }

  updateImportantNote(token: string, data): Observable<any> {
    return this.api.put(`user/notes/${token}`, data).pipe(map(response => response as any));
  }

  updateUserSocialMedia(id: number, data: any): Observable<any> {
    return this.api.put(`users/${id}/info`, data).pipe(map(response => response as any));
  }

  updateAvatar(id: number, data: any): Observable<UserAvatar> {
    return this.api.post(`users/${id}/avatar`, data).pipe(map(response => response as UserAvatar));
  }

  deleteAvatar(id: number): Observable<UserAvatar> {
    return this.api.delete(`users/${id}/avatar`);
  }

  editUserAbout(data: any): Observable<any> {
    return this.api.put(`users/about`, data);
  }

  subscribeUser(data: any): Observable<any> {
    return this.api.post(`user-subscriptions`, data);
  }

  getReviews(id: number): Observable<TableResponse<Review[]>> {
    return this.api.get(`reviews/user/${id}`).pipe(map(response => response));
  }

  addReview(data: any): Observable<{id: number, token: string}> {
    return this.api.post(`reviews`, data).pipe(map(response => response as {id: number, token: string}));
  }

  addDateRange(data: any): Observable<{token: string}> {
    return this.api.post(`users/calendar`, data).pipe(map(response => response as {token: string}));
  }

  addNote(data: any): Observable<Note> {
    return this.api.post(`user/notes`, data).pipe(map(response => response as Note));
  }

  createContactFromUser(id: number, userId: number): Observable<{id: number}> {
    return this.api.post(`users/${id}/contacts/from-user/${userId}`, {}).pipe(map(response => response as {id: number}));
  }

  updateBackgroundImage(data: any): Observable<UserAvatar> {
    return this.api.post(`users/background-image`, data).pipe(map(response => response as UserAvatar));
  }

  reportUser(id: number, data: any): Observable<any> {
    return this.api.post(`user-report/${id}`, data);
  }

  lockUser(id: number): Observable<any> {
    return this.api.post(`user-lock/${id}`, {});
  }

  unlockUser(id: number): Observable<any> {
    return this.api.delete(`user-lock/${id}`);
  }

  removeDateRange(token: string): Observable<any> {
    return this.api.delete(`users/calendar/${token}`);
  }

  removeRealization(token: string): Observable<any> {
    return this.api.delete(`users/realization/${token}`);
  }

  unsubscribeUser(id: number): Observable<any> {
    return this.api.delete(`user-subscriptions/${id}`);
  }

  removeImportantNote(token: string): Observable<any> {
    return this.api.delete(`user/notes/${token}`);
  }

  removeFollower(id: number, fid: number): Observable<any> {
    return this.api.delete(`users/${id}/followers/${fid}`);
  }

  removeAllNotifications(): Observable<any> {
    return this.api.delete(`profile/notifications/all`);
  }

  removeSelectedNotifications(data: any): Observable<any> {
    return this.api.post(`profile/notifications`, data);
  }

  getResetPasswordLink(data: {email: string}): Observable<any> {
    return this.api.post(`start-password-reset`, data).pipe(map(response => response as any));
  }

  addUserNote(data: any): Observable<{token: string}> {
    return this.api.post(`user/notes`, data).pipe(map(response => response as {token: string}));
  }

  register(data): Observable<any> {
    return this.api.post(`users-register`, data).pipe(map(response => response as any));
  }

  registerIdentity(data): Observable<any> {
    return this.api.post(`users-register-identity`, data).pipe(map(response => response as any));
  }

  activateUserAccount(token: string): Observable<any> {
    return this.api.put(`users/activate/${token}`, {}).pipe(map(response => response as any));
  }

  finalizeRegistration(userId: number, data): Observable<any> {
    return this.api.put(`users/${userId}/finalize-registration-process`, data).pipe(map(response => response as any));
  }

  makeInvitationDecision(token: string, data: any): Observable<any> {
    return this.api.put(`invitations/${token}/decide`, data).pipe(map(response => response as any));
  }

  updateUserPermissions(id: number, userId: number, data: any): Observable<any> {
    return this.api.put(`investments/${id}/users/${userId}/permissions`, data).pipe(map(response => response as any));
  }

  resetPassword(data): Observable<any> {
    return this.api.post(`reset-password`, data).pipe(map(response => response as any));
  }

  removeAccount() {
    return this.api.delete(`me`);
  }
}
