import { Injectable } from '@angular/core';
import { BaseRequestComponent } from '@app/_components/base-request.component';
import { Comment } from '@app/_interfaces/comment.interface';
import { TableResponse } from '@app/_models/table-response.model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '.';

@Injectable()
export class CommentsService extends BaseRequestComponent {
  public commentChanged = new Subject<{comment: Comment, action: string}>();
	public commentChanged$ = this.commentChanged.asObservable();

  constructor(private api: ApiService) {
    super();
  }

  change(comment: Comment, action: string) {
    this.commentChanged.next({comment, action});
  }

  add(message: string, id: number, url: string) {
    const sub = this.addComment(id, { content: message }, url).subscribe((comment: Comment) => {
      this.commentChanged.next({comment, action: 'add'});
    }, error => {
      if (this.errorService.showMessage(error)) {
        this.notifications.onError(this.translate.instant(`ERRORS.cant_add_comment`));
      }
    });
    this.subscriptions.add(sub);
  }

  addFloorComment(message: string, id: number, token: string) {
    const sub = this.addNewFloorComment(id, { content: message }, token).subscribe((comment: Comment) => {
      this.commentChanged.next({comment, action: 'add'});
    }, error => {
      if (this.errorService.showMessage(error)) {
        this.notifications.onError(this.translate.instant(`ERRORS.cant_add_comment`));
      }
    });
    this.subscriptions.add(sub);
  }

  remove(id: number, comment: Comment, url: string) {
    const sub = this.removeComment(id, comment.token, url).subscribe(_ => {
      this.commentChanged.next({comment, action: 'remove'});
    }, error => {
      if (this.errorService.showMessage(error)) {
        this.notifications.onError(this.translate.instant(`ERRORS.cant_remove_comment`));
      }
    });
    this.subscriptions.add(sub);
  }


  //api calls

  addComment(id: number, data, url: string): Observable<Comment> {
    return this.api.post(`comments/${url}/${id}`, data).pipe(map(response => response as Comment));
  }

  addNewFloorComment(id: number, data, token: string): Observable<Comment> {
    return this.api.post(`investments/${id}/spots/${token}/comments`, data).pipe(map(response => response as Comment));
  }

  getComments(id: number, url: string): Observable<TableResponse<Comment[]>> {
    return this.api.get(`comments/${url}/${id}`).pipe(map(response => response as TableResponse<Comment[]>));
  }

  getFloorComments(id: number, token: string): Observable<TableResponse<Comment[]>> {
    return this.api.get(`investments/${id}/spots/${token}/comments`).pipe(map(response => response as TableResponse<Comment[]>));
  }

  removeComment(id: number, token: string, url: string): Observable<any> {
    return this.api.delete(`comments/${url}/${id}/${token}`);
  }
}
