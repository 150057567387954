import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '@app/_services/user.service';
import { MenuItem } from '@app/_interfaces/menu-item.interface';
import { User } from '@app/_models';
import { AuthService } from '@app/_services/auth/auth.service';
import { SseService } from '@app/_services/sse.service';
import { BaseRequestComponent } from '../base-request.component';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent extends BaseRequestComponent implements OnInit {
  @Input() user: User;

  menuItems: MenuItem[] = [
    {
      name: 'dashboard',
      icon: 'speedometer',
      url: '/dashboard',
      disabled: this.common.isBasicSubscription()
    },
    {
      name: 'costs',
      icon: 'cash',
      url: '/expenses',
      disabled: this.common.isBasicSubscription()
    },
    {
      name: 'lost_of_ads',
      icon: 'bookmark',
      url: '/favourites'
    },
    {
      name: 'invoices',
      icon: 'document-attach',
      url: 'invoices',
      disabled: this.common.isBasicSubscription()
    },
    {
      name: 'calculator',
      icon: 'calculator',
      url: '/calculator',
      disabled: this.common.isBasicSubscription()
    },
    {
      name: 'settings',
      icon: 'settings',
      url: '/settings'
    }
  ];

  menuLogoutItem: MenuItem = {
    name: 'logout',
    icon: 'log-out',
    url: 'logout',
    noReorder: true
  };

  menu = this.common.isBasicSubscription() ? [
    ...this.menuItems.filter(item => !item.disabled && !item.noReorder),
    ...this.menuItems.filter(item => item.disabled || item.noReorder),
  ] : this.menuItems;

  used: number = 0;
  limit: number = 0;

  constructor(public authService: AuthService, private sseService: SseService, private userService: UserService) {
    super();
  }

  ngOnInit() {
      const sub = this.userService.getCoworkers().subscribe((response: {used: number, limit: number}) => {
        this.used = response.used;
        this.limit = response.limit;
      }, error => {
        if (this.errorService.showMessage(error)) {
          this.notifications.onError(this.translate.instant(`ERRORS.cant_get_cooworkers_number`));
        }
      });
      this.subscriptions.add(sub);
  }

  go(item: MenuItem) {
    if (item.disabled) {
      return;
    }
    this.close();
    switch (item.url) {
      case 'logout':
        this.logout();
        break;
      default:
        this.router.navigate([item.url]);
        break;
    }
  }

  openUserProfile(id: number) {
    this.router.navigate([`/dashboard/user/${id}`]);
    this.close();
  }

  close() {
    document.body.classList.remove('main-menu-open');
  }

  logout() {
    this.sseService.disconnect();
    this.authService.logout();
  }
}
