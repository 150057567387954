import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'randomImage' })
export class RandomImagePipe implements PipeTransform {
    transform(value: string): any {
      if (!value) {
        const images = ['1', '2', '3', '4'];
        const random = Math.floor(Math.random() * images.length);
        return random + 1;
      }
    }
}
