import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationsService } from '@app/_services';
import { AuthService } from '@app/_services/auth/auth.service';
import { SseService } from '@app/_services/sse.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  errorCounter: number = 0;

	constructor(
    private authService: AuthService, private sseService: SseService, private notifications: NotificationsService) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
      catchError(err => {
        this.errorCounter++;
        if ([401, 403].indexOf(err.status) !== -1) {
          this.sseService.disconnect();
          this.authService.logout(this.errorCounter <= 1 ? true : false);
        } else if ([406].indexOf(err.status) !== -1) {
          this.notifications.onInfo('Aby mieć dostęp do tej części aplikacji wykup wyższy plan.');
        }
			  return throwError(err);
		  })
    );
	}
}
