import { Injectable } from '@angular/core';
import { StorageService } from '.';

@Injectable({
	providedIn: 'root'
})
export class PermissionsService {
  constructor(private storage: StorageService) {}

    isPermitted(name: string): boolean {
        const permissions = JSON.parse(this.storage.get('permissions'));
        return permissions[name];
    }
}
