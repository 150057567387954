/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@app/_components/base.component';
import { Token } from '@app/_interfaces';
import { Role, User } from '@app/_models';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationsService } from '..';
import { StorageService } from '../storage.service';
import { UserService } from '../user.service';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseComponent {
  public token: Observable<Token>;
  public mercureToken: Observable<Token>;
  public user: Observable<User>;
  public tokenSubject: BehaviorSubject<Token>;
  public mercureTokenSubject: BehaviorSubject<Token>;
  public userSubject: BehaviorSubject<User>;

  constructor(
    private storage: StorageService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private notifications: NotificationsService

  ) {
    super();
    this.tokenSubject = new BehaviorSubject<Token>(
      JSON.parse(this.storage.get('token'))
    );
    this.mercureTokenSubject = new BehaviorSubject<Token>(
      JSON.parse(this.storage.get('mercure-token'))
    );
    this.userSubject = new BehaviorSubject<User>(
      JSON.parse(this.storage.get('user'))
    );
    this.token = this.tokenSubject.asObservable();
    this.mercureToken = this.mercureTokenSubject.asObservable();
    this.user = this.userSubject.asObservable();
  }

  public get tokenValue(): Token {
    return this.tokenSubject.value;
  }

  public get mercureTokenValue(): Token {
    return this.mercureTokenSubject.value;
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  setToken(token: Token) {
    this.storage.put('token', JSON.stringify(token));
    this.tokenSubject.next(token);
    this.getMercureToken();
    this.login();
  }

  setIDPToken(token: string, platform: string) {
    this.storage.put('tokenIDP', JSON.stringify({token, platform}));
  }

  login() {
    const sub = this.userService.getUser().subscribe((user: User) => {
      this.storage.put('user', JSON.stringify(user));
      window.dispatchEvent(new Event('userInStorage'));
      this.userSubject.next(user);
      this.route.queryParams.subscribe(params => {
        if (params.invitation_token) {
          this.router.navigate(['/accept-invitation'], {replaceUrl: true, queryParams: {invitation_token: params.invitation_token}});
        } else {
          if (user.role !== Role.Guest) {
            this.storage.delete('tokenIDP');
          }
          this.router.navigate(['/dashboard'], {replaceUrl: true});
        }
      });
    });
    this.subscriptions.add(sub);
  }

  getMercureToken() {
    const sub = this.userService.getMercureToken().subscribe((token: Token) => {
      this.storage.put('mercure-token', JSON.stringify(token));
      this.mercureTokenSubject.next(token);
    });
    this.subscriptions.add(sub);
  }

  logout(tokenExpired?: boolean) {
    this.storage.delete('token');
    this.storage.delete('tokenIDP');
    this.storage.delete('mercure-token');
    this.storage.delete('user');
    this.storage.delete('logged');

    this.tokenSubject.next(null);
    this.mercureTokenSubject.next(null);
    this.userSubject.next(null);
    this.userService.statusPingShouldSubscribe.next(false);

    this.navCtrl.navigateRoot(['/login']);

    if (tokenExpired) {
      this.notifications.onWarning('Sesja wygasła. Zaloguj się ponownie.');
    }
  }
}
