import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { InvestmentsService } from '@app/_services/investments.service';
import { Observable } from 'rxjs';

@Injectable()
export class FloorSketchResolver implements Resolve<any> {
  constructor(private investmentService: InvestmentsService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const id = +route.params.id;

    document.removeEventListener('open-planner', () => {}, false);
    document.removeEventListener('blueprint-savejson', () => {}, false);
    document.removeEventListener('blueprint-download', () => {}, false);

    return this.investmentService.getFloors(id);
  }
}
