/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/naming-convention */
import { DefaultAddress } from '@app/_interfaces/default-address.interface';
import { UserAvatar } from '@app/_interfaces/user-avatar.interface';
import { Role } from './role';

export class User {
	id: number;
  email: string;
  public_email: string;
  about: string;
	role: Role;
  avatar: UserAvatar;
  contact_id?: number;
  social_media?: UserSocialMedia;
  current_subscription?: UserCurrentSubscription;
  background_image?: UserAvatar;
  shipping_address: DefaultAddress;
  billing_address?: DefaultAddress;
  token?: string;
  profession: string;
  locked?: boolean;
  subscribed?: boolean;
  rating_average?: number;
  rating_amount?: number;
  posts_count: number;
  followers_count: number;
  following_count: number;
  files_count?: number;
  display_name?: string;
  unseen_messages_count?: number;

  constructor(data?: any) {
    Object.assign(this, data);
  }

  get first_name() {
    return this.shipping_address.first_name;
  }

  get last_name() {
    return this.shipping_address.last_name;
  }

  get phone() {
    return this.shipping_address.phone;
  }

  get prefix() {
    return this.shipping_address.prefix;
  }

  get country_code() {
    return this.shipping_address.country_code;
  }

  get province() {
    return this.shipping_address.province;
  }

  get postcode() {
    return this.shipping_address.postcode;
  }

  get city() {
    return this.shipping_address.city;
  }

  get street() {
    return this.shipping_address.street;
  }

  get company() {
    return this.shipping_address.company;
  }

  get vat_number() {
    return this.billing_address ? this.billing_address.vat_number : null;
  }

  get avatar_src() {
    return this.avatar ? this.avatar.src : null;
  }

  get description() {
    return this.about ? this.about : 'BRAK POLA ABOUT';
  }
}

export interface UserSocialMedia {
  twitter: string;
  facebook: string;
  linkedin: string;
  website: string;
}

export interface UserCurrentSubscription {
  valid_to: string;
  plan_name: string;
  plan_slug: string;
  plan_id: number;
}
