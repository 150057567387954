import { Injectable } from '@angular/core';
import { Chat, ChatMessage, SimpleFile } from '@app/_interfaces';
import { Response, TableResponse } from '@app/_models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '.';

@Injectable({
  providedIn: 'root',
})
export class ChatService {

  constructor(private api: ApiService) {}

  getAllChats(): Observable<Response<Chat[]>> {
    return this.api.get(`chats`).pipe(map(response => response as Response<Chat[]>));
  }

  createNewChat(data: any): Observable<Chat> {
    return this.api.post(`chats`, data).pipe(map(response => response as Chat));
  }

  sendMessage(id: number, data: {message: string}): Observable<ChatMessage> {
    return this.api.post(`chats/${id}/messages`, data).pipe(map(response => response as any));
  }

  sendMessageImage(id: number, mid: number, data: any): Observable<SimpleFile> {
    return this.api.post(`chats/${id}/messages/${mid}/images`, data).pipe(map(response => response as any));
  }

  removeMessage(id: number, messageId: number): Observable<any> {
    return this.api.delete(`chats/${id}/messages/${messageId}`).pipe(map(response => response as any));
  }

  getPrivateChat(id: number): Observable<{id: number}> {
    return this.api.get(`private-chat/${id}`).pipe(map(response => response as {id: number}));
  }

  getMessagesWithUser(id: number, page: number): Observable<TableResponse<ChatMessage[]>> {
    return this.api.get(`chats/${id}/messages?page=${page}`).pipe(map(response => response as TableResponse<ChatMessage[]>));
  }

  markChatAsSeen(id): Observable<void> {
    return this.api.put(`chats/${id}/seen`, {});
  }
}
