import { Injectable } from '@angular/core';
import { DefaultAddress } from '@app/_interfaces/default-address.interface';
import { EventTask } from '@app/_interfaces/event-task.interface';
import { ExpenseCategory } from '@app/_interfaces/expense-category.interface';
import { Expense } from '@app/_interfaces/expense.interface';
import { Floor } from '@app/_interfaces/floor.interface';
import { InvestmentInfo } from '@app/_interfaces/investmen-info.interface';
import { InvestmentLocalization } from '@app/_interfaces/investment-localization.interface';
import { Person } from '@app/_interfaces/person.interface';
import { PurchaseInfo } from '@app/_interfaces/purchase-info.interface';
import { PurchaseSellTokens } from '@app/_interfaces/purchase-sell-tokens.interface';
import { SimpleFile } from '@app/_interfaces/simple-file.interface';
import { FileResponse } from '@app/_models/file-response';
import { File } from '@app/_models/file.model';
import { Investment } from '@app/_models/investment.model';
import { TableResponse } from '@app/_models/table-response.model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '.';

@Injectable()
export class InvestmentsService {

  public mainImageChanged = new Subject<SimpleFile>();
	public mainImageChanged$ = this.mainImageChanged.asObservable();

  public expenseAdded = new Subject<boolean>();
	public expenseAdded$ = this.expenseAdded.asObservable();

  public investmentFinished = new Subject<boolean>();
	public investmentFinished$ = this.investmentFinished.asObservable();

  public blueprintSaved = new Subject<boolean>();
	public blueprintSaved$ = this.blueprintSaved.asObservable();

  public investmentRemoved = new Subject<boolean>();
	public investmentRemoved$ = this.investmentRemoved.asObservable();

  constructor(private api: ApiService) {}

  mainImageChange(image: SimpleFile) {
    this.mainImageChanged.next(image);
  }

  onAddExpense() {
    this.expenseAdded.next(true);
  }

  onBlueprintSave() {
    this.blueprintSaved.next(true);
  }

  onInvestmentFinish() {
    this.investmentFinished.next(true);
  }

  onInvestmentRemove() {
    this.investmentRemoved.next(true);
  }

  // api calls

  createInvestment(data: any): Observable<Investment> {
    return this.api.post(`investments`, data).pipe(map(response => response as Investment));
  }

  createExpense(id: number, data: any): Observable<Expense> {
    return this.api.post(`investments/${id}/expense`, data).pipe(map(response => response as Expense));
  }

  uploadPurchaseSellFile(id: number, token: string, data: any): Observable<any> {
    return this.api.post(`investments/${id}/step/files/${token}`, data);
  }

  uploadFloorFile(id: number, token: string, data: any): Observable<any> {
    return this.api.post(`investments/${id}/floor/${token}/blueprint`, data);
  }

  sendInvitation(id: number, data: any): Observable<any> {
    return this.api.post(`investments/${id}/invite-user`, data);
  }

  saveCalculator(id: number, data: any): Observable<any> {
    return this.api.put(`investments/${id}/flip-calculator`, data);
  }

  addInvestmentImage(id: number, data: any): Observable<SimpleFile> {
    return this.api.post(`investments/${id}/images`, data).pipe(map(response => response as SimpleFile));
  }

  createSpot(data: any, id: number): Observable<{token: string}> {
    return this.api.post(`investments/${id}/spots`, data).pipe(map(response => response as {token: string}));
  }

  addExpenseCategory(data: any): Observable<{id: number}> {
    return this.api.post(`expense-categories`, data).pipe(map(response => response as {id: number}));
  }

  uploadFileToSpot(data: any, id: number, token: string): Observable<any> {
    return this.api.post(`investments/${id}/spots/${token}/image`, data).pipe(map(response => response as any));
  }

  uploadExpenseFile(id: number, expenseId: number, data: any): Observable<any> {
		return this.api.post(`investments/${id}/expense/${expenseId}/image`, data).pipe(map(response => response as any));
	}

  getInvestmentsImages(id: number): Observable<TableResponse<SimpleFile[]>> {
    return this.api.get(`investments/${id}/images`).pipe(map(response => response as TableResponse<SimpleFile[]>));
  }

  getExpenseCategories(): Observable<ExpenseCategory[]> {
    return this.api.get(`expense-categories`).pipe(map(response => response as ExpenseCategory[]));
  }

  getCalculator(id: number): Observable<any> {
    return this.api.get(`investments/${id}/flip-calculator`).pipe(map(response => response as any));
  }

  getFloorData(id: number, floorToken: number): Observable<any> {
    return this.api.get(`investments/${id}/floors/${floorToken}`).pipe(map(response => response as any));
  }

  getInvestmentAddress(id: number): Observable<DefaultAddress> {
    return this.api.get(`investments/${id}/address`).pipe(map(response => response as DefaultAddress));
  }

  downloadDXFFile(id: number, token: string): Observable<any> {
    return this.api.get(`investments/${id}/floors/${token}/dxf`).pipe(map(response => response as any));
  }

  getSpotList(id: number, params): Observable<any> {
    return this.api.get(`investments/${id}/spots`, params).pipe(map(response => response as any));
  }

  getInvestmentSummary(id: number): Observable<any> {
    return this.api.get(`investments/${id}/summary/costs`).pipe(map(response => response as any));
  }

  getAllEvents(): Observable<any> {
    return this.api.get(`investments/events`).pipe(map(response => response as any));
  }

  getSummary(id: number): Observable<any> {
    return this.api.get(`investments/${id}/summary`).pipe(map(response => response as any));
  }

  getInvestmentPermissions(id: number, userId: number): Observable<any> {
    return this.api.get(`investments/${id}/permissions/${userId}`);
  }

  getInvestmentExpenses(id: number, params: any = {}): Observable<TableResponse<Expense[]>> {
    return this.api.get(`investments/${id}/expense`, params).pipe(map(response => response as TableResponse<Expense[]>));
  }

  getFloors(id: number): Observable<Floor[]> {
    return this.api.get(`investments/${id}/floors`).pipe(map(response => response as Floor[]));
  }

  getMainImage(id: number): Observable<SimpleFile> {
    return this.api.get(`investments/${id}/images/main-image`).pipe(map(response => response as SimpleFile));
  }

  getMyInvestments(params: any = {}): Observable<TableResponse<Investment[]>> {
    return this.api.get(`me/investments`, params).pipe(map(response => response as TableResponse<Investment[]>));
  }

  getInvestmentLocalizations(params: any = {}): Observable<InvestmentLocalization[]> {
    return this.api.get(`me/investments/localizations`, params).pipe(map(response => response as InvestmentLocalization[]));
  }

  getIncomingEvents(id: number): Observable<EventTask[]> {
    return this.api.get(`investments/${id}/events`, { order: 'DESC', limit: 3}).pipe(map(response => response as EventTask[]));
  }

  getLastFiles(id: number): Observable<File[]> {
    return this.api.get(`file-manager/investment/${id}/last`).pipe(map(response => response as File[]));
  }

  getInvestmentUsersList(id: number, type: string): Observable<Person[]> {
    return this.api.get(`investments/${id}/users/${type}`).pipe(map(response => response as Person[]));
  }

  showInvestment(id: number): Observable<Investment> {
    return this.api.get(`investments/${id}`).pipe(map(response => response as Investment));
  }

  getRootFolderUuid(id: number): Observable<{token: string}> {
    return this.api.get(`investments/${id}/file-manager-root-dir`).pipe(map(response => response as {token: string}));
  }

  getPurchaseSellDirTokens(id: number): Observable<PurchaseSellTokens> {
    return this.api.get(`investments/${id}/step/directories`).pipe(map(response => response as PurchaseSellTokens));
  }

  getPurchaseInfo(id: number, type: string): Observable<PurchaseInfo> {
    return this.api.get(`investments/${id}/step-apartment-${type}-info`).pipe(
      map(response => response as PurchaseInfo)
    );
  }

  getInvestmentInfo(id: number): Observable<InvestmentInfo> {
    return this.api.get(`investments/${id}/step-apartment-info`).pipe(map(response => response as InvestmentInfo));
  }

  getPurchaseSellFiles(id: number, token: string): Observable<FileResponse> {
    return this.api.get(`investments/${id}/step/files/${token}`).pipe(map(response => response as FileResponse));
  }

  updateInvestmentInfo(id: number, data: any): Observable<any> {
    return this.api.put(`investments/${id}/step-apartment-info`, data);
  }

  updateExpenseCategory(id: number, data: any): Observable<any> {
    return this.api.put(`expense-categories/${id}`, data);
  }

  uploadFloorSketch(id: number, token: string, data: any): Observable<any> {
    return this.api.put(`investments/${id}/floors/${token}/sketch`, data);
  }

  updateExpense(id: number, expenseId: number, data: any): Observable<any> {
    return this.api.put(`investments/${id}/expense/${expenseId}`, data);
  }

  finalizeInvestment(id: number): Observable<any> {
    return this.api.put(`investments/${id}/finish`, {});
  }

  setMainImage(id: number, token: string): Observable<any> {
    return this.api.put(`investments/${id}/images/${token}/mark-as-main`, {});
  }

  setNotification(id: number, personId: number, data: any): Observable<any> {
    return this.api.put(`investments/${id}/users/${personId}/requirements`, data);
  }

  updateInvestmentAddress(id: number, data: DefaultAddress): Observable<any> {
    return this.api.put(`investments/${id}/address`, {address: data});
  }

  updatePurchaseInfo(id: number, type: string, data: any): Observable<any> {
    return this.api.put(`investments/${id}/step-apartment-${type}-info`, data);
  }

  removeUserFromInvestment(id: number, personId: number): Observable<any> {
    return this.api.delete(`investments/${id}/users/${personId}`);
  }

  removeInvestmentExpense(id: number, expenseId: number): Observable<any> {
    return this.api.delete(`investments/${id}/expense/${expenseId}`);
  }

  removeImage(id: number, token: string): Observable<any> {
    return this.api.delete(`investments/${id}/images/${token}`);
  }

  removeFloor(id: number, token: string): Observable<any> {
    return this.api.delete(`investments/${id}/floors/${token}`);
  }

  deleteExpenseCategory(id: number): Observable<any> {
    return this.api.delete(`expense-categories/${id}`);
  }

  removeInvestment(id: number): Observable<any> {
    return this.api.delete(`investments/${id}`);
  }
}
