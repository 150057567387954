import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Role } from '@app/_models';
import { AuthComponent } from '@app/_pages/auth/auth.component';
import { RegisterComponent } from '@app/_pages/auth/register/register.component';
import { NotFoundComponent } from '@app/_pages/not-found/not-found.component';
import { AuthGuard } from './_helpers';
import { GuestGuard } from './_helpers/guest.guard';
import { SingleStepRegistrationGuard } from '@app/_helpers/single-step-registration.guard';
import { SubscriptionGuard } from '@app/_helpers/subscription.guard';
import { AcceptInvitationComponent } from './_pages/auth/accept-invitation/accept-invitation.component';
import { AcceptRegulationsComponent } from './_pages/auth/accept-regulations/accept-regulations.component';
import { ForgotPasswordCompletedComponent } from './_pages/auth/forgot-password-completed/forgot-password-completed.component';
import { ForgotPasswordComponent } from './_pages/auth/forgot-password/forgot-password.component';
import { RegisterActivateComponent } from './_pages/auth/register-activate/register-activate.component';
import { RegisterCompletedComponent } from './_pages/auth/register-completed/register-completed.component';
import { RegisterSelectAccountTypeComponent } from './_pages/auth/register-select-account-type/register-select-account-type.component';
import {
  RegulationsSelectAccountTypeComponent
} from './_pages/auth/regulations-select-account-type/regulations-select-account-type.component';
import { ResetPasswordCompletedComponent } from './_pages/auth/reset-password-completed/reset-password-completed.component';
import { ResetPasswordComponent } from './_pages/auth/reset-password/reset-password.component';
import { PagesComponent } from '@app/_pages/auth/pages/pages.component';
import { FloorSketchResolver } from './_resolvers/floor-sketch.resolver';
import { InvestmentPermissionsResolver } from './_resolvers/investment-permissions.resolver';
import { InvestmentResolver } from './_resolvers/investment.resolver';
import { PostResolver } from './_resolvers/post.resolver';
import { UserResolver } from './_resolvers/user.resolver';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./_pages/dashboard/dashboard.module').then((m) => m.DashboardPageModule),
    canActivate: [AuthGuard, GuestGuard],
    data: { roles: [Role.Guest, Role.Admin, Role.Investor, Role.Builder] },
  },
  {
    path: 'login',
    loadChildren: () => import('./_pages/auth/auth.module').then((m) => m.AuthModule),
    component: AuthComponent,
  },
  {
    path: 'register',
    loadChildren: () => import('./_pages/auth/auth.module').then((m) => m.AuthModule),
    component: RegisterComponent,
  },
  {
    path: 'register/completed',
    loadChildren: () => import('./_pages/auth/auth.module').then((m) => m.AuthModule),
    component: RegisterCompletedComponent,
  },
  {
    path: 'register/regulations/accept',
    loadChildren: () => import('./_pages/auth/auth.module').then((m) => m.AuthModule),
    component: AcceptRegulationsComponent,
  },
  {
    path: 'register/regulations/select-account-type',
    loadChildren: () => import('./_pages/auth/auth.module').then((m) => m.AuthModule),
    component: RegulationsSelectAccountTypeComponent,
    canActivate: [SingleStepRegistrationGuard]
  },
  {
    path: 'register/activate/:token',
    loadChildren: () => import('./_pages/auth/auth.module').then((m) => m.AuthModule),
    component: RegisterActivateComponent,
  },
  {
    path: 'register/select-account-type',
    loadChildren: () => import('./_pages/auth/auth.module').then((m) => m.AuthModule),
    component: RegisterSelectAccountTypeComponent,
    canActivate: [SingleStepRegistrationGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./_pages/auth/auth.module').then((m) => m.AuthModule),
    component: ForgotPasswordComponent,
  },
  {
    path: 'forgot-password/completed',
    loadChildren: () => import('./_pages/auth/auth.module').then((m) => m.AuthModule),
    component: ForgotPasswordCompletedComponent,
  },
  {
    path: 'reset-password/new-password/:token',
    loadChildren: () => import('./_pages/auth/auth.module').then((m) => m.AuthModule),
    component: ResetPasswordComponent,
  },
  {
    path: 'reset-password/completed',
    loadChildren: () => import('./_pages/auth/auth.module').then((m) => m.AuthModule),
    component: ResetPasswordCompletedComponent,
  },
  {
    path: 'accept-invitation',
    loadChildren: () => import('./_pages/auth/auth.module').then((m) => m.AuthModule),
    component: AcceptInvitationComponent
  },
  {
    path: 'posts/:id',
    loadChildren: () => import('./_pages/wall-post-show/wall-post-show.module').then((m) => m.WallPostShowModule),
    resolve: {
      post: PostResolver
    },
  },
  {
    path: 'favourites',
    loadChildren: () => import('./_pages/favourites/favourites.module').then((m) => m.FavouritesModule),
    canActivate: []
  },
  {
    path: 'expenses',
    loadChildren: () => import('./_pages/user-expenses/user-expenses.module').then((m) => m.UserExpensesModule),
    canActivate: [SubscriptionGuard]
  },
  {
    path: 'wall-notifications',
    loadChildren: () => import('./_pages/notifications/notifications.module').then((m) => m.NotificationsModule),
  },
  {
    path: 'messages',
    loadChildren: () => import('./_pages/messages/messages.module').then((m) => m.MessagesModule),
  },
  {
    path: 'invoices',
    loadChildren: () => import('./_pages/invoices/invoices.module').then((m) => m.InvoicesModule),
    canActivate: [SubscriptionGuard]
  },
  {
    path: 'calculator',
    loadChildren: () => import('./_pages/calculator/calculator.module').then((m) => m.CalculatorModule),
    canActivate: [SubscriptionGuard]
  },
  {
    path: 'last-calls',
    loadChildren: () => import('./_pages/last-calls/last-calls.module').then((m) => m.LastCallsModule),
    canActivate: []
  },
  {
    path: 'splash',
    loadChildren: () => import('./_pages/splashscreen/splashscreen.module').then((m) => m.SplashscreenModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./_pages/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'dashboard/investment/:id',
    loadChildren: () => import('./_pages/investment/investment.module').then((m) => m.InvestmentModule),
    resolve: {
      investment: InvestmentResolver,
      permissions: InvestmentPermissionsResolver
    },
    canActivate: []
  },
  {
    path: 'dashboard/investment/:id/room-planner/:floor',
    loadChildren: () => import('./_pages/room-planner/room-planner.module').then((m) => m.RoomPlannerModule),
    resolve: {
      floors: FloorSketchResolver
    },
    canActivate: []
  },
  {
    path: 'dashboard/user/:id',
    loadChildren: () => import('./_pages/user/user.module').then((m) => m.UserModule),
    resolve: {
      user: UserResolver
    },
  },
  {
    path: 'dashboard/contact/:id',
    loadChildren: () => import('./_pages/contact/contact.module').then((m) => m.ContactModule),
    canActivate: []
  },
  {
    path: 'dashboard/investment/:id/timetable/:teamid',
    loadChildren: () => import('./_pages/investment-timetable/investment-timetable.module').then((m) => m.InvestmentTimetableModule),
    canActivate: []
  },
  {
    path: 'dashboard/investment/:id/stage/:tab',
    loadChildren: () => import('./_pages/investment-stages/investment-stages.module').then((m) => m.InvestmentStagesModule),
    canActivate: []
  },
  {
    path: 'dashboard/investment/:id/investment-summary',
    loadChildren: () =>
      import('./_pages/investment-stages/_pages/investment-summary/investment-summary.module').then((m) => m.InvestmentSummaryModule),
    canActivate: []
  },
  {
    path: 'terms-of-service',
    loadChildren: () => import('./_pages/auth/auth.module').then((m) => m.AuthModule),
    component: PagesComponent
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./_pages/auth/auth.module').then((m) => m.AuthModule),
    component: PagesComponent
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/dashboard'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
