/* eslint-disable @typescript-eslint/naming-convention */
export class TableResponse<T> {
  current_page: number;
  data?: T;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
  draft_counter?: number;
}
