import { EventEmitter, Injectable, Output } from '@angular/core';
import { UrlController } from '@app/_helpers/url.controller';
import { FiltersData } from '@app/_models/filters-data.model';
import { FiltersParams } from '@app/_models/filters-params.model';
import { ApiService } from '.';

@Injectable()
export class FiltersService {
    @Output() filterChange: EventEmitter<any> = new EventEmitter();
    params: FiltersParams = new FiltersParams();
    urlController: UrlController = new UrlController();
    filtersEmitter: EventEmitter<FiltersData> = new EventEmitter();

    constructor(public api: ApiService) {
    }

    setParams(params) {
        this.filterChange.emit(params);
        Object.assign(this.params, params);
    }

    getParams() {
        return this.api.get(this.params.api_url, this.clearParams());
    }

    clearParams() {
        const params = {};
        for (const key in this.params) {
            if (!this.params.hasOwnProperty(key) || key === 'api_url' || this.params[key] === '') {
                continue;
            }
            params[key] = this.params[key];
        }
        for (const key in params) {
            if (this.params.hasOwnProperty(key)) {
                if (Array.isArray(params[key])) {
                    params[key] = params[key].join(',');
                }
            }
        }
        return params;
    }
}
