import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AddressPipe } from './address.pipe';
import { CustomCurrencyPipe } from './custom-currency.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { MomentPipe } from './moment.pipe';
import { RandomImagePipe } from './random-image.pipe';
import { SortPipe } from './sort.pipe';
import { StarsPipe } from './stars.pipe';
import { ThumbnailPipe } from './thumbnail.pipe';

@NgModule({
  declarations: [
    AddressPipe,
    CustomCurrencyPipe,
    LinkifyPipe,
    MomentPipe,
    RandomImagePipe,
    SortPipe,
    StarsPipe,
    ThumbnailPipe
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule
  ],
  exports: [
    AddressPipe,
    CustomCurrencyPipe,
    LinkifyPipe,
    MomentPipe,
    RandomImagePipe,
    SortPipe,
    StarsPipe,
    ThumbnailPipe
  ],
  providers: [
    AddressPipe,
    CustomCurrencyPipe,
    LinkifyPipe,
    MomentPipe,
    RandomImagePipe,
    SortPipe
  ]
})

export class SharedPipesModule { }
