import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { StorageService } from '@app/_services';
import { InvestmentsService } from '@app/_services/investments.service';
import { Observable } from 'rxjs';

@Injectable()
export class InvestmentPermissionsResolver implements Resolve<any> {
  constructor(private investmentService: InvestmentsService, private storage: StorageService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const id = +route.params.id;
    const user = JSON.parse(this.storage.get('user'));
    return this.investmentService.getInvestmentPermissions(id, user.id);
  }
}
