import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';
import { Gesture, GestureController } from '@ionic/angular';
import { Subject } from 'rxjs';


@Injectable()
export class GestureService extends HammerGestureConfig {
  public menuClosed = new Subject<boolean>();
	public menuClosed$ = this.menuClosed.asObservable();

  constructor(private gestureCtrl: GestureController) {
    super();
    this.addListener();
  }

  async closeActionSheet() {
    const el = document.getElementsByClassName('action-sheet-title')[0];
    if (el) {
      const gesture: Gesture = await this.gestureCtrl.create({
        el: document.getElementsByClassName('action-sheet-title')[0],
        gestureName: 'container-swipe',
        gesturePriority: 100,
        threshold: 1,
        direction: 'y',
        onEnd: (event) => {
          if (event.deltaY > 0) {
            this.menuClosed.next(true);
          }
        }
      });
      gesture.enable();
    }
  }

  async openSubMenu(el) {
  }

  addListener() {
    document.addEventListener('ionActionSheetDidPresent', () => this.closeActionSheet());
  }
}
