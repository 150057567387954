/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '@app/_configs/config';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(private http: HttpClient) {
	}

	static getUrl(url: string) {
		return `${Config.apiUrl}/${url}`;
	}

	public post(url: string, body: any, options: any = {}): Observable<any> {
		return this.http.post(ApiService.getUrl(url), body, options).pipe(map(response => response), catchError(this.errorHandler('POST')));
	}

  public post2(url: string, body: any, options: any = {}): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
    headers.append('Access-Control-Expose-Headers', 'Content-Length');
		return this.http.post(ApiService.getUrl(url), body, {
      reportProgress: true,
      observe: 'events',
      responseType: 'json',
      headers,
      params: new HttpParams({fromObject: options.params})
    }).pipe(map(response => response), catchError(this.errorHandler('POST')));
	}

	public put(url: string, body: any, options: any = {}): Observable<any> {
		return this.http.put(ApiService.getUrl(url), body, options).pipe(map(resp => resp), catchError(this.errorHandler('PUT')));
	}

	public get(
    url: string,
    params?: any,
    responseType: any = 'json',
    observe: any = 'body',
    reportProgress: any = false): Observable<any> {
		return this.http.get(ApiService.getUrl(url), {
        responseType,
        observe,
        reportProgress,
        params: new HttpParams({fromObject: params})
      }).pipe(
			map(response => response),
      catchError(this.errorHandler('GET')
		));
	}

	public delete(url: string, options: any = {}): Observable<any> {
		return this.http.delete(ApiService.getUrl(url), options).pipe(map(resp => resp), catchError(this.errorHandler('DELETE')));
	}

  errorHandler(operation) {
    return (e: HttpErrorResponse) => throwError(e.error);
  }
}
