import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Investment } from '@app/_models/investment.model';
import { InvestmentsService } from '@app/_services/investments.service';
import { Observable } from 'rxjs';

@Injectable()
export class InvestmentResolver implements Resolve<Investment> {
  constructor(private investmentService: InvestmentsService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Investment> {
    const id = +route.params.id;
    return this.investmentService.showInvestment(id);
  }
}
