import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService, StorageService } from '@app/_services';
import { AppInjector } from '@app/_services/app-injector.service';
import { CommonService } from '@app/_services/common.service';
import { ErrorService } from '@app/_services/error.service';
import { LoadingService } from '@app/_services/loading.service';
import { PermissionsService } from '@app/_services/permissions.service';
import { RoleService } from '@app/_services/role.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from './base.component';

@Component({
  template: ''
})
export abstract class BaseRequestComponent extends BaseComponent {

  public loading: boolean = false;

  loadingService: LoadingService;
  public common: CommonService;
  notifications: NotificationsService;
  storage: StorageService;
  translate: TranslateService;
  errorService: ErrorService;
  alertCtrl: AlertController;
  roleService: RoleService;
  router: Router;
  location: Location;
  permissionsService: PermissionsService;

  protected constructor() {
    super();
    const injector = AppInjector.getInjector();
    this.loadingService = injector.get(LoadingService);
    this.common = injector.get(CommonService);
    this.notifications = injector.get(NotificationsService);
    this.storage = injector.get(StorageService);
    this.translate = injector.get(TranslateService);
    this.errorService = injector.get(ErrorService);
    this.alertCtrl = injector.get(AlertController);
    this.roleService = injector.get(RoleService);
    this.router = injector.get(Router);
    this.location = injector.get(Location);
    this.permissionsService = injector.get(PermissionsService);
  }
}
