import { StatusBarBackground } from '@app/_interfaces/status-bar-background.interface';
import { Tag } from '@app/_interfaces/tag.interface';
import { environment } from '@environments/environment';


export class Config {
	static apiUrl: string = environment.apiUrl;
  static avatarStyle: any = {
    border: '3px solid #F1F1F6',
    borderRadius: '10px',
    height: '48px',
    width: '48px'
  };
  static statusBarBackground: StatusBarBackground = {
    dark: '#12171F',
    light: '#F8F9FB'
  };
  // Warszawa
  static defaultCoords = {
    latitude: 52.2297700,
    longitude: 21.0117800
  };
  // skrajne punkty Polski
  static edgeCoords = [
    [54.836172476859424, 18.30299380962736],
    [50.86894783486698, 24.14583258159869],
    [49.00255498704168, 22.84697459167753],
    [52.839635921038855, 14.124412972445933]
  ];
  static leafletMapAccessToken: string = 'NRnEoKVJNwxUJaH5yKeqsZE1GeFq92GZqrYz5ERxM45ZTBzKJMLbmJbHML4Z2x0t';
  static leafletMap: string = `https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=${Config.leafletMapAccessToken}`;
  static defaultDateFormat: string = 'DD-MM-YYYY';
  static backendDateFormat: string = 'YYYY-MM-DD HH:mm:ss Z';
  static prettyDateFormat: string = 'DD MMM, YYYY';
  static weekFromDateFormat: string = 'DD MMM';
  static weekToDateFormat: string = 'DD MMM, YYYY';
  static monthFormat: string = 'MMMM YYYY';
  static importantNoteDateFormat: string = 'DD MMM, YYYY - HH:mm';
  static prettyDateTimeFormat: string = 'DD MMM, YYYY HH:mm';
  static fileNameTimestampFormat: string = 'DDMMYYYY_HHmmss';
  static onlyDayNumberDateFormat: string = 'DD';
  static uploadFileSizeInBytes: number = 20000000; // 20MB
  static allowedFloorsImageTypes: string[] = [
    'image/jpeg',
    'image/png'
  ];
  static allowedImageTypes: string[] = [
    'image/jpeg',
    'image/png',
    'image/heic',
    'image/heif'
  ];
  static allowedFileTypes: string[] = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/heic',
    'image/heif',
    'multipart/x-zip', // .zip
    'application/x-compressed', // .zip
    'application/x-zip-compressed', // .zip
    'application/zip', // .zip
    'application/pdf',
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //docx
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/acad',
    'text/plain',
    'audio/mpeg3',
    'audio/mpeg',
    'video/mpeg',
    'video/quicktime',
    'video/avi',
    'video/webm',
    'video/mp4'
  ];
  static allowedPurchaseSellFileTypes: string[] = [
    'image/jpeg',
    'image/png',
    'image/heic',
    'image/heif',
    'application/pdf',
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //docx
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/plain'
  ];
  static allowedTaskFileTypes: string[] = [
    'image/jpeg',
    'image/png',
    'image/heic',
    'image/heif',
    'application/pdf',
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //docx
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/plain'
  ];
  static userPermissions: string[] = [
    'investment_base_data',
    'investment_realizations',
    'investment_contacts',
    'investment_files',
    'investment_notes',
    'investment_events',
    'investment_finances'
  ];
  static postTypes: Tag[] = [
    {
      selected: false,
      type: 'opportunity'
    },
    {
      selected: false,
      type: 'capital'
    },
    {
      selected: false,
      type: 'forum'
    },
    {
      selected: false,
      type: 'education'
    },
  ];
  static avatarColors: string[] = ['#FF5D6E', '#5DA3FF', '#9D5DFF'];
  static voivodeships = [
    '',
    'dolnoslaskie',
    'kujawsko-pomorskie',
    'lubelskie',
    'lubuskie',
    'lodzkie',
    'malopolskie',
    'mazowieckie',
    'opolskie',
    'podkarpackie',
    'podlaskie',
    'pomorskie',
    'slaskie',
    'swietokrzyskie',
    'warminsko-mazurskie',
    'wielkopolskie',
    'zachodniopomorskie',
  ];
}
