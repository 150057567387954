import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Role } from '@app/_models';
import { AuthService } from '@app/_services/auth/auth.service';
import { CommonService } from '@app/_services/common.service';

@Component({
  selector: 'app-regulations-select-account-type',
  templateUrl: './regulations-select-account-type.component.html',
  styleUrls: ['./regulations-select-account-type.component.css']
})
export class RegulationsSelectAccountTypeComponent implements OnInit {

  extras: NavigationExtras;

  constructor(public common: CommonService, private router: Router, private authService: AuthService) { }

  ngOnInit() {
    if (this.authService.userValue?.role !== Role.Guest) {
      this.router.navigate(['/'], {replaceUrl: true});
    }
  }

  next(extras: NavigationExtras) {
    this.extras = extras;
  }

  nextPage() {
    this.router.navigate(['/register/regulations/accept'], this.extras);
  }

}
