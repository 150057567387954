import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

	get(key: string) {
		return localStorage.getItem(key);
	}

	put(key: string, val: any) {
		localStorage.setItem(key, val);
	}

	delete(key: string) {
		localStorage.removeItem(key);
	}

  get user() {
    return JSON.parse(this.get('user'));
  }

  get getUserAvatar(): string {
    return this.user.avatar_src;
  }
}
