import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Role } from '@app/_models';
import { AuthService } from '@app/_services/auth/auth.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {
	constructor(private router: Router, private authService: AuthService) {}

  canActivate() {
    const user = this.authService.userValue;

    if (user?.role === Role.Guest) {
      this.router.navigate(
        [environment?.features?.singleStepRegistration ? '/register' : '/register/regulations/select-account-type'],
        {replaceUrl: true}
      );
      return false;
    }
    return true;
  }
}
