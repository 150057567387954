/* eslint-disable @typescript-eslint/no-unused-expressions */

import { FiltersAction } from '@app/_enums/filters-action.enum';
import { FiltersDataStatus } from '@app/_enums/filters-data-status.enum';
import { FiltersParams } from './filters-params.model';


export class FiltersData {
    status: FiltersDataStatus = FiltersDataStatus.SUCCESS;
    records: any;
    action: FiltersAction;
    params?: FiltersParams;

    constructor(data?: any) {
        data ? Object.assign(this, data) : null;
    }
}
