import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@app/_components/base.component';
import { CommonService } from '@app/_services/common.service';

@Component({
  selector: 'app-forgot-password-completed',
  templateUrl: './forgot-password-completed.component.html',
  styleUrls: ['./forgot-password-completed.component.scss']
})
export class ForgotPasswordCompletedComponent extends BaseComponent implements OnInit {

  email: string;

  constructor(public common: CommonService, private route: ActivatedRoute, private router: Router) {
    super();
  }

  ngOnInit() {
    const sub = this.route.queryParams.subscribe(_ => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.email = this.router.getCurrentNavigation().extras.state.email;
      }
    });
    this.subscriptions.add(sub);
  }

}
