import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { CommonService } from '@app/_services/common.service';

@Component({
  selector: 'app-register-select-account-type',
  templateUrl: './register-select-account-type.component.html',
  styleUrls: ['./register-select-account-type.component.scss']
})
export class RegisterSelectAccountTypeComponent implements OnInit {

  extras: NavigationExtras;

  constructor(public common: CommonService, private router: Router) {}

  ngOnInit() {
  }

  next(extras: NavigationExtras) {
    this.extras = extras;
  }

  nextPage() {
    this.router.navigate(['/register'], this.extras);
  }
}
