import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '@app/_configs/config';
import { Errors } from '@app/_enums/error.enum';
import { Geolocation, Position } from '@capacitor/geolocation';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { AlertController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from './common.service';

@Injectable()
export class GeoService {
  public geolocationPermission = new Subject<{ data: Position, error: any }>();
  public geolocationPermission$ = this.geolocationPermission.asObservable();

  observableAddress: Observable<any>;

  config = Config;

  constructor(
    private http: HttpClient,
    private alertCtrl: AlertController,
    private openNaticeSettings: OpenNativeSettings, private common: CommonService) {}

  getPosition(forceAlert?: boolean) {
    Geolocation.getCurrentPosition().then((position: Position) => {
      this.geolocationPermission.next({ data: position, error: null });
    }).catch(error => {
      this.geolocationPermission.next({ data: null, error: 'disable' });
      if (error.message === Errors.LOCATION_UNAVAILABLE && forceAlert) {
        this.presentLocationInstructionAlert();
      }
      if (error.errorMessage === Errors.OPERATION_NOT_COMPLETED && forceAlert) {
        this.presentLocationInstructionAlert();
      }
      if (error.message === Errors.DENIED_GEOLOCATION && forceAlert) {
        this.presentLocationInstructionAlert();
      }
    });
  }

  fetchFullAddressFromCoords(event: {name: string, location: any}): Observable<any> {
    const url = 'https://nominatim.openstreetmap.org/reverse.php?';
    const lat = event ? event.location.latitude : this.config.defaultCoords.latitude;
    const lng = event ? event.location.longitude : this.config.defaultCoords.longitude;
    return this.http.get(`${url}lat=${lat}&lon=${lng}&zoom=18&format=jsonv2`).pipe(map(response => response as any));
  }

  fetchCoordsFromAddress(address) {
    const url = `https://nominatim.openstreetmap.org/search/${address}?format=json&addressdetails=1&limit=1&polygon_svg=1`;
    return this.http.get(url).pipe(map(response => response as any));
  }

  async presentLocationInstructionAlert() {
    const webOptions = {
      header: 'Brak geolokalizacji',
      message: 'Aby pobrać dane o Twoim położeniu włącz na swoim urządzeniu geolokalizację',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.geolocationPermission.next({ data: null, error: {message: 'disable'}});
          }
        }
      ]
    };
    const mobileOptions = {
      header: 'Brak geolokalizacji',
      message: 'Aby pobrać dane o Twoim położeniu włącz na swoim urządzeniu geolokalizację',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.geolocationPermission.next({ data: null, error: {message: 'disable'}});
          }
        },
        {
          text: 'USTAWIENIA',
          handler: async () => {
            this.openNaticeSettings.open(await this.common.isIos() ? 'locations' : 'location');
            this.geolocationPermission.next({ data: null, error: {message: 'disable'}});
          }
        }
      ]
    };
    const alert = await this.alertCtrl.create(await this.common.isWeb() ? webOptions : mobileOptions);
    await alert.present();
  }
}
