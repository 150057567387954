import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SuperTabsService {
  public tabChanged = new Subject<number | string>();
	public tabChanged$ = this.tabChanged.asObservable();

  public wallTabActivated = new Subject<number | string>();
	public wallTabActivated$ = this.wallTabActivated.asObservable();

  constructor() {
  }

  async changeTab(index: number) {
    this.tabChanged.next(index);
  }

  async changeTabByName(name: string) {
    this.tabChanged.next(name);
  }

  async activateWallTab(index: number) {
    this.wallTabActivated.next(index);
  }
}
