import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@app/_services/auth/auth.service';
import { NotificationsService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuard implements CanActivate {
	constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private notifications: NotificationsService) {}

  canActivate() {
    const user = this.authService.userValue;
    if (user?.current_subscription?.plan_slug === 'basic') {
      this.notifications.onInfo(this.translate.instant('APP.basic_plan_info'));
      return false;
    } else {
      return true;
    }
  }
}
