import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SingleStepRegistrationGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate() {
    if (!environment?.features?.singleStepRegistration) {
      return true;
    }
    this.router.navigate(['/register'], {replaceUrl: true});
    return false;
  }
}
