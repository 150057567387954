import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '@app/_services/auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private authService: AuthService) {}

	canActivate(route: ActivatedRouteSnapshot) {
		const user = this.authService.userValue;
		const token = this.authService.tokenValue;
		if (user && token) {
			// check if route is restricted by role
			// if (route.data.roles && route.data.roles.indexOf(user.role) === -1) {
			// 	this.router.navigateByUrl('/splash');
        	// 	return false;
			// }
			// authorised so return true
			return true;
		}
		// not logged in so redirect to login page with the return url
		this.authService.logout();
		return false;
	}
}
