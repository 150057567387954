/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */

import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ErrorInterceptor, JwtInterceptor } from '@app/_helpers';
import { NotFoundComponent } from '@app/_pages/not-found/not-found.component';
import { StorageService } from '@app/_services/storage.service';
import { environment } from '@environments/environment';
import { Camera } from '@ionic-native/camera/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { SuperTabs, SuperTabsModule } from '@ionic-super-tabs/angular';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LongPressModule } from 'ionic-long-press';
import { ClickOutsideModule } from 'ng-click-outside';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule } from 'ngx-timeago';
import { strings as plStrings } from 'ngx-timeago/language-strings/pl';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CropperService } from './_components/cropper/cropper.service';
import { MainMenuComponent } from './_components/main-menu/main-menu.component';
import { GestureConfig } from './_helpers/gesture-config';
import { SharedPipesModule } from './_pipes/shared-pipes.module';
import { FloorSketchResolver } from './_resolvers/floor-sketch.resolver';
import { InvestmentPermissionsResolver } from './_resolvers/investment-permissions.resolver';
import { InvestmentResolver } from './_resolvers/investment.resolver';
import { PostResolver } from './_resolvers/post.resolver';
import { UserResolver } from './_resolvers/user.resolver';
import { AppInjector } from './_services/app-injector.service';
import { CommentsService } from './_services/comments.service';
import { CommonService } from './_services/common.service';
import { ContactsService } from './_services/contacts.service';
import { ErrorService } from './_services/error.service';
import { EventService } from './_services/event.service';
import { FcmService } from './_services/fcm.service';
import { FileManagerService } from './_services/file-manager.service';
import { FiltersService } from './_services/filters.service';
import { GeoService } from './_services/geo.service';
import { GestureService } from './_services/gesture.service';
import { InvestmentsService } from './_services/investments.service';
import { MapService } from './_services/map.service';
import { ModalService } from './_services/modal.service';
import { PermissionsService } from './_services/permissions.service';
import { RoleService } from './_services/role.service';
import { SseService } from './_services/sse.service';
import { SuperTabsService } from './_services/super-tabs.service';
import { TimetableService } from './_services/timetable.service';
import { WallService } from './_services/wall.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Injectable()
export class MyIntl extends TimeagoIntl {
  strings = plStrings;
}

const maskConfig: Partial<IConfig> = {
  validation: false,
  showTemplate: false
};

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    MainMenuComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    IonicModule.forRoot({}),
    SuperTabsModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    SnotifyModule,
    NgSelectModule,
    ClickOutsideModule,
    LongPressModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'pl'
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    SharedPipesModule,
    TimeagoModule.forRoot({
      intl: { provide: TimeagoIntl, useClass: MyIntl },
      formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter },
    }),
    NgxMaskModule.forRoot(maskConfig),
    InfiniteScrollModule
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    StorageService,
    {
      provide: 'SnotifyToastConfig',
      useValue: ToastDefaults
    },
    {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
    {
      provide: LOCALE_ID,
      useValue: 'pl'
    },
    SnotifyService,
    CommonService,
    ErrorService,
    SuperTabs,
    Camera,
    ModalService,
    CropperService,
    GeoService,
    OpenNativeSettings,
    GestureService,
    SuperTabsService,
    EventService,
    WallService,
    ContactsService,
    FcmService,
    SseService,
    InvestmentsService,
    FileManagerService,
    UserResolver,
    InvestmentResolver,
    InvestmentPermissionsResolver,
    FileOpener,
    RoleService,
    MapService,
    TimetableService,
    FiltersService,
    CommentsService,
    PermissionsService,
    FloorSketchResolver,
    PostResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
