import { Component } from '@angular/core';
import { BaseRequestComponent } from '@app/_components/base-request.component';
import { Config } from '@app/_configs/config';
import { ModalService } from '@app/_services/modal.service';
import { UserService } from '@app/_services/user.service';
import { Style } from '@capacitor/status-bar';

@Component({
  selector: 'app-regulations-modal',
  templateUrl: './regulations-modal.component.html',
  styleUrls: ['./regulations-modal.component.scss']
})
export class RegulationsModalComponent extends BaseRequestComponent {

  tos: string = null;

  constructor(public modalService: ModalService, private userService: UserService) {
    super();
  }

  download() {

  }

  ionViewWillEnter() {
    this.common.setStatusBar(Style.Light);
    this.common.setStatusBarBackground(Config.statusBarBackground.light);

    this.getTos();
  }

  ionViewWillLeave() {
    this.common.setStatusBar(Style.Dark);
    this.common.setStatusBarBackground(Config.statusBarBackground.dark);
  }

  getTos() {
    this.userService.getTos().subscribe((response) => {
      this.tos = response.content;
    });
  }

}
