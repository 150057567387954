import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';


@Injectable()
export class TimetableService {
  constructor(private api: ApiService) {}

  getTimetable(id: number, teamId: number): Observable<any> {
    return this.api.get(`investments/${id}/timetable/${teamId}`);
  }

  changeStartDate(id: number, teamId: number, data: any): Observable<any> {
    return this.api.put(`investments/${id}/timetable/${teamId}/change-start-date`, data);
  }

  updateTask(id: number, teamId: number, token: string, data: any): Observable<any> {
    return this.api.put(`investments/${id}/timetable/${teamId}/${token}`, data);
  }

  removeTask(id: number, teamId: number, token: string): Observable<any> {
    return this.api.delete(`investments/${id}/timetable/${teamId}/${token}`);
  }

  addTask(id: number, teamId: number, data: any): Observable<{ token: string }> {
    return this.api.post(`investments/${id}/timetable/${teamId}`, data).pipe(map(response => response as { token: string }));
  }
}
