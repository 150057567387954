import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { User } from '@app/_models';
import { UserService } from '@app/_services/user.service';
import { Observable } from 'rxjs';

@Injectable()
export class UserResolver implements Resolve<User> {
  constructor(private userService: UserService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<User> {
    const id = +route.params.id;
    return this.userService.showProfile(id);
  }
}
