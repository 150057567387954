/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseRequestComponent } from '@app/_components/base-request.component';
import { AuthService } from '@app/_services/auth/auth.service';
import { UserService } from '@app/_services/user.service';

@Component({
  selector: 'app-accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.scss']
})
export class AcceptInvitationComponent extends BaseRequestComponent implements OnInit {

  token: string;
  decision: boolean = true;

  constructor(private authService: AuthService, private route: ActivatedRoute, private userService: UserService) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    const sub = this.route.queryParams.subscribe(params => {
      if (params.invitation_token) {
        if (this.authService.userValue && this.authService.tokenValue) {
          this.token = params.invitation_token;
          this.loading = false;
        } else {
          this.router.navigate([`/login`], {queryParams: {invitation_token: params.invitation_token}});
        }
      } else {
        this.router.navigate([`/login`]);
      }
    });
    this.subscriptions.add(sub);
  }

  async makeDecision() {
    await this.loadingService.presentLoading();
    const data = { decision: this.decision ? 'accept' : 'reject' };
    const sub = this.userService.makeInvitationDecision(this.token, data).subscribe(async _ => {
      await this.loadingService.dismiss();
      this.router.navigate(['/dashboard']);
      this.notifications.onSuccess(this.translate.instant(`AUTH.invitation_was_${data.decision}`));
    }, async error => {
      await this.loadingService.dismiss();
      if (this.errorService.showMessage(error)) {
        this.notifications.onError(this.translate.instant('ERRORS.cant_make_decision'));
      }
    });
    this.subscriptions.add(sub);
  }

  onDecisionChange(event) {
    this.decision = event.detail.checked;
  }

}
